<section class="container-fluid">
  <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Accounts Details</h2>
  <div class="card rounded-8 border p-3">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="row h-100">
          <div class="col-md-6 border-right h-100">
            <div>
              <label class="label-gray font-14 mb-0">Contact Name</label>
              <h3 class="font-16 font-weight-semi-bold m-0">Mathew John Senior</h3>
            </div>
            <div class="mt-3">
              <label class="label-gray font-14 mb-0">Email</label>
              <h3 class="font-16 font-weight-semi-bold m-0">mathewjohn@gmail.com</h3>
            </div>
          </div>
          <div class="col-md-6 border-right h-100">
            <div>
              <label class="label-gray font-14 mb-0">Sign Up Date</label>
              <h3 class="font-16 font-weight-semi-bold m-0">10/14/2020 06:06 AM(EST)</h3>
            </div>
            <div class="mt-3">
              <label class="label-gray font-14 mb-0">IP Address</label>
              <div> <a href="https://extreme-ip-lookup.com/73.90.123.154" target="_blank" class="line-height30"><u>73.90.123.154</u></a></div>
            </div>
          </div>
          </div>
      </div>

      <div class="col-lg-4 border-right">
        <div class="row align-items-center">
          <div class="col-md-5">
            <div class="card secondary-light-bg-color border-secondary shadow-sm text-center p-2">
              <h3 class="primary-color font-weight-bold mb-2">Tax Forms</h3>
              <span class="secondary-color font-weight-bold font-20">07</span>
            </div>
          </div>
          <div class="col-md-7">
            <table class="table mBot0 width100 account-detls-table low-width-table">
              <tbody>
                <tr>
                  <td class="border-0">
                    Transmitted Forms<br />
                    <b class="primary-color">03</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    Inprogress Forms<br />
                    <b class="primary-color">05</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
      </div>

      <div class="col-lg-3 text-center">
        <a href="javascript:Void(0);" class="btn btn-primary btn-lg btn-primary-one mt-0 mb-0 font-weight-bold"><i class="fa fa-clone" aria-hidden="true"></i> Co-browse</a>
      </div>
    </div>
  </div>

  <div class="accounts-tab-version mt-4">
    <nav class="inner-tab-style">
      <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
        <a class="nav-item nav-link" id="nav-home-tab" data-toggle="tab" href="#nav-accountstab1" role="tab" aria-controls="nav-accountstab1" aria-selected="true">Call Notes</a>
        <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-accountstab2" role="tab" aria-controls="nav-accountstab2" aria-selected="false">Additional Details</a>
        <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-accountstab3" role="tab" aria-controls="nav-accountstab3" aria-selected="false">Payment History</a>
        <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-accountstab4" role="tab" aria-controls="nav-accountstab4" aria-selected="false">Tax Forms</a>
        <a class="nav-item nav-link active" id="nav-about-tab" data-toggle="tab" href="#nav-accountstab5" role="tab" aria-controls="nav-accountstab5" aria-selected="false">Actions</a>
        <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-accountstab6" role="tab" aria-controls="nav-accountstab6" aria-selected="false">Feedbacks</a>
        <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-accountstab7" role="tab" aria-controls="nav-accountstab7" aria-selected="false">Activity Log</a>
        <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-accountstab8" role="tab" aria-controls="nav-accountstab8" aria-selected="false">Pricing Group</a>
        <a class="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-accountstab9" role="tab" aria-controls="nav-accountstab9" aria-selected="false">Prepaid Credits</a>
      </div>
    </nav>
    <div class="tab-content pt-3 pb-0 px-3 px-sm-0" id="nav-tabContent">
      <div class="tab-pane fade" id="nav-accountstab1" role="tabpanel" aria-labelledby="nav-home-tab">
        <div class="row">
          <div class="col-lg-8 pr-0">
            <div class="border rounded-8 p-3">
              <!-- Box -->
              <div class="shadow light-shadow card rounded-8 border p-3 mt-3">
                <div class="row inner-box-width">
                  <div class="col-12 mb-2">
                    <a href="page4.html"><span class="primary-color font-500 font-16 text-underline">johnsmith@gmail.com </span></a>
                    <span class="text-italic">
                      <label class="label-gray">Contacted  on</label>
                      <span>07/26/2020 06:23 PM (EST)</span>
                      <label class="label-gray">through</label><span>Chat</span>
                    </span>
                    <span class="float-right"><label class="label-gray mb-0 mr-1">Category:</label><span>Form W-2</span></span>
                  </div>

                  <div class="col-lg-4">
                    <label class="label-gray mb-0 mr-1">Follow Up Date:</label>
                    <span class="mt-1">07/26/2020 10:00 AM (EST)</span>
                  </div>

                  <div class="col-lg-4">
                    <div class="d-inline-block w-100">
                      <label class="text-black-50 mr-1 mb-0">Status:</label>
                      <mat-form-field class="w-50">
                        <mat-select [(value)]="selectedStatus">
                          <mat-option [value]="option.value" *ngFor="let option of status">{{ option.viewValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="d-inline-block w-100">
                      <label class="text-black-50 mr-1 mb-0">Assigned To:</label>
                      <mat-form-field class="w-50">
                        <mat-select [(value)]="selectedAssign">
                          <mat-option [value]="option.value" *ngFor="let option of assign">{{ option.viewValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-lg-12">
                    <div class="activity-block d-inline-block">
                      <div><span class="label-gray mb-0 mr-0">Next Activity:</span> <span class="">No further follow up required</span></div>
                      <div><span class="label-gray mb-0 mr-0">What:</span> <span class="">The client wants to know when his W2 form will be approved by the IRS</span></div>
                      <div><span class="label-gray mb-0 mr-0">Comments:</span> <span class=""> Typical IRS will take 2 to 3 days to update the status of your return. We will send an email once the return is approved.</span></div>
                    </div>
                  </div>

                </div>
              </div>
              <!-- Box -->
              <!-- Box -->
              <div class="shadow light-shadow card rounded-8 border p-3 mt-3">
                <div class="row inner-box-width">
                  <div class="col-12 mb-2">
                    <a href="page4.html"><span class="primary-color font-500 font-20 text-underline">alexanderdaniel@gmail.com</span></a>
                    <span class="text-italic">
                      <label class="label-gray mb-0 mr-0">Contacted  on</label>
                      <span>07/26/2020 06:13 PM (EST)</span>
                      <label class="label-gray mb-0 mr-0">through</label><span>Mail</span>
                    </span>
                    <span class="float-right"><label class="label-gray mb-0 mr-0">Category:</label><span>Form W-2</span></span>
                  </div>

                  <div class="col-lg-4">
                    <label class="label-gray mb-0 mr-0">Follow Up Date:</label>
                    <span class="mt-1">07/26/2020 10:00 AM (EST)</span>
                  </div>

                  <div class="col-lg-4">
                    <div class="d-inline-block w-100">
                      <label class="text-black-50 mr-1 mb-0">Status:</label>
                      <mat-form-field class="w-50">
                        <mat-select [(value)]="selectedStatus">
                          <mat-option [value]="option.value" *ngFor="let option of status">{{ option.viewValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="d-inline-block w-100">
                      <label class="text-black-50 mr-1 mb-0">Assigned To:</label>
                      <mat-form-field class="w-50">
                        <mat-select [(value)]="selectedAssign">
                          <mat-option [value]="option.value" *ngFor="let option of assign">{{ option.viewValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-lg-12">
                    <div class="activity-block d-inline-block">
                      <div><span class="label-gray mb-0 mr-0">Next Activity:</span> <span class="">No further follow up required</span></div>
                      <div><span class="label-gray mb-0 mr-0">What:</span> <span class="">User is unable to login into his account</span></div>
                      <div><span class="label-gray mb-0 mr-0">Comments:</span> <span class="">Advised the client to reset the password</span></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Box -->
              <!-- Box -->
              <div class="shadow light-shadow card rounded-8 border p-3 mt-3">
                <div class="row inner-box-width">
                  <div class="col-12 mb-2">
                    <a href="page4.html"><span class="primary-color font-500 font-20 text-underline">Logan Aiden (logantransport@gmail.com)</span></a>
                    <span class="text-italic">
                      <label class="label-gray mb-0 mr-0">Contacted  on</label>
                      <span>07/26/2020 05:20 PM (EST)</span>
                      <label class="label-gray mb-0 mr-0">through</label><span>Mail</span>
                    </span>
                    <span class="float-right"><label class="label-gray mb-0 mr-0">Category:</label><span>Form W-2</span></span>
                  </div>

                  <div class="col-lg-4">
                    <label class="label-gray mb-0 mr-0">Follow Up Date:</label>
                    <span class="mt-1">07/27/2020 10:00 AM (EST)</span>
                  </div>

                  <div class="col-lg-4">
                    <div class="d-inline-block w-100">
                      <label class="text-black-50 mr-1 mb-0">Status:</label>
                      <mat-form-field class="w-50">
                        <mat-select [(value)]="selectedStatus">
                          <mat-option [value]="option.value" *ngFor="let option of status">{{ option.viewValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="d-inline-block w-100">
                      <label class="text-black-50 mr-1 mb-0">Assigned To:</label>
                      <mat-form-field class="w-50">
                        <mat-select [(value)]="selectedAssign">
                          <mat-option [value]="option.value" *ngFor="let option of assign">{{ option.viewValue }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-lg-12">
                    <div class="activity-block d-inline-block">
                      <div class="activity-block d-inline-block">
                        <div><span class="label-gray mb-0 mr-0">Next Activity:</span> <span class="">No further follow up required</span></div>
                        <div><span class="label-gray mb-0 mr-0">What:</span> <span class="">Unable to download the generated forms from the dashboard.</span></div>
                        <div><span class="label-gray mb-0 mr-0">Comments:</span> <span class="">Provided the instructions to download the forms.</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Box -->

            </div>
          </div>
          <div class="col-lg-4">
            <div class="shadow light-shadow card rounded-8 border p-3 popup-modal-style">
              <div class="row">
                <div class="col-lg-12">
                  <div class="radio-btn-selector">
                    <div class="radio-btn-selector">
                      <div class="custom-control custom-radio custom-control-inline pl-0">
                        <mat-radio-group aria-label="Select an option">
                          <mat-radio-button value="1" class="mr-5">Chat</mat-radio-button>
                          <mat-radio-button value="2">Mail</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                  <mat-form-field class="example-form-field w-100">
                    <mat-label>What</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                  <div>
                    <span class="label-gray">Assigned to: </span>
                    <mat-select class="template-select">
                      <mat-option *ngFor="let catagory of catagory" [value]="catagory.value">
                        {{catagory.viewValue}}
                      </mat-option>
                    </mat-select>
                  </div>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Catagory</mat-label>
                    <mat-select>
                      <mat-option *ngFor="let catagory of catagory" [value]="catagory.value">
                        {{catagory.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="example-form-field w-100">
                    <mat-label>Comments*</mat-label>
                    <input matInput type="text">
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Assigned to*</mat-label>
                    <mat-select>
                      <mat-option *ngFor="let assign of assign" [value]="assign.value">
                        {{assign.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select>
                      <mat-option *ngFor="let status of status" [value]="status.value">
                        {{status.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>


                  <div class="mt-3">
                    <div class="mt-3">
                      <label class="label">Contacted on<sup class="redcolor">*</sup></label>
                      <div class="row">
                        <div class="col-lg-6">
                          <form action="/action_page.php" class="date-picker-field">
                            <input type="date" id="birthday" name="birthday">
                          </form>
                        </div>
                        <div class="col-lg-6">
                          <form action="/action_page.php" class="date-picker-field">
                            <input type="time" id="appt" name="appt">
                          </form>
                        </div>
                      </div>
                    </div>

                    <div class="mt-3">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                        <label class="custom-control-label black-color" for="customCheck1" checked>Follow Up</label>
                      </div>
                      <label class="label mTop5">Follow Up Date<sup class="redcolor">*</sup></label>
                      <div class="row">
                        <div class="col-lg-6">
                          <form action="/action_page.php" class="date-picker-field">
                            <input type="date" id="birthday" name="birthday">
                          </form>
                        </div>
                        <div class="col-lg-6">
                          <form action="/action_page.php" class="date-picker-field">
                            <input type="time" id="appt" name="appt">
                          </form>
                        </div>
                      </div>
                    </div>
                    <form class="form-inline mt-3">
                      <label class="label">Next Activity<sup class="redcolor">*</sup></label>
                      <input class="form-control mr-sm-2 w-100" type="search" placeholder="Enter here" aria-label="Search">
                    </form>
                    <div class="mt-3 text-center">
                      <a href="" class="btn btn-primary btn-lg btn-primary-two mt-0 mb-0">Save</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal pop up -->
      <div class="modal fade popup-modal-style calhistory-popup" id="callHistory-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">Information <i class="fa fa-commenting-o" aria-hidden="true"></i></h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="what-info">
                <span>What</span>
                <p class="mBot0">The client wants to know when his W-2 form will be approved by the IRS</p>
              </div>
              <div class="comments-info mTop20">
                <span>Comments</span>
                <p class="mBot0">Typical IRS will take 2 to 3 days to update the status of your return. We will send an email once the return is approved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal pop up -->
      <!-- Modal pop up1 -->
      <div class="modal fade popup-modal-style calhistory-popup" id="callHistory-popup1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">Information <i class="fa fa-commenting-o" aria-hidden="true"></i></h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="what-info">
                <span>What</span>
                <p class="mBot0">User is unable to login in his account.</p>
              </div>
              <div class="comments-info mTop20">
                <span>Comments</span>
                <p class="mBot0">Advised the client to reset the password.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal pop up1 -->
      <!-- Modal pop up2 -->
      <div class="modal fade popup-modal-style calhistory-popup" id="callHistory-popup2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">Information <i class="fa fa-commenting-o" aria-hidden="true"></i></h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="what-info">
                <span>What</span>
                <p class="mBot0">Unable to download the generated form the dashboard.</p>
              </div>

              <div class="comments-info mTop20">
                <span>Comments</span>
                <p class="mBot0">Provide the details steps download the forms.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- Modal pop up2 -->

      <div class="tab-pane fade" id="nav-accountstab2" role="tabpanel" aria-labelledby="nav-home-tab">
        <div class="additional-details-block">
          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Payer/Employer Details
                    <span class="float-right">
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </h5>
              </div>
              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-lg-2">
                      <div class="d-flex align-items-center">
                        <span class="primary-color mr-3">Show</span>
                        <div class="dropdown w-25">
                          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            10
                          </button>
                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#">20</a>
                            <a class="dropdown-item" href="#">30</a>
                            <a class="dropdown-item" href="#">40</a>
                          </div>
                        </div>
                        <span class="primary-color ml-3">Entries</span>
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <div class="d-flex align-items-center">
                        <span class="input-label mr-3">Tax Year</span>
                        <div class="dropdown w-50">
                          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            2020
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">2021</a>
                            <a class="dropdown-item" href="#">2022</a>
                            <a class="dropdown-item" href="#">2023</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 ml-auto">
                      <form class="form-inline my-2 my-lg-0 float-right">
                        <input class="form-control mr-sm-2 search-bar" type="search" placeholder="Search" aria-label="Search">
                      </form>
                    </div>
                  </div>

                  <table class="table table-bordered mt-3">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col" class="width-50 text-center">S.No.</th>
                        <th scope="col">Payer/Employer</th>
                        <th scope="col">Employee/Recipient</th>
                        <th scope="col">No. of Tax forms E-Filed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">1</td>
                        <td>April 30 Tech</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="nav-accountstab3" role="tabpanel" aria-labelledby="nav-profile-tab">
        <div class="payment-history-block">
          <form class="form-inline mb-2">
            <input class="form-control mr-sm-2 search-bar" type="search" placeholder="Order ID/Total Amount" aria-label="Search">
          </form>
          <div class="row align-items-center">
            <div class="col-lg-2">
              <div class="d-flex align-items-center">
                <span class="primary-color mr-3">Show</span>
                <div class="dropdown w-25">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    10
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">20</a>
                    <a class="dropdown-item" href="#">30</a>
                    <a class="dropdown-item" href="#">40</a>
                  </div>
                </div>
                <span class="primary-color ml-3">Entries</span>
              </div>
            </div>
            <div class="col-lg-8 ml-auto">
              <form class="form-inline my-2 my-lg-0 float-right">
                <input class="form-control mr-sm-2 search-bar" type="search" placeholder="Search" aria-label="Search">
              </form>
            </div>
          </div>
          <table class="table table-bordered mt-3 table-head-bg">
            <thead class="thead-light">
              <tr>
                <th scope="col" class="width-50 text-center">S.No.</th>
                <th scope="col">Order Id</th>
                <th scope="col">Generated On(EST)</th>
                <th scope="col" class="text-center">Quantity</th>
                <th scope="col">Postal Mailing<small>(Yes/No)</small></th>
                <th scope="col" class="text-right">Subtotal</th>
                <th scope="col" class="text-right">Discount</th>
                <th scope="col" class="text-right">Total Amount</th>
                <th scope="col">Payment Status</th>
                <th scope="col" class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">1</td>
                <td>EPS04020000006</td>
                <td>03/27/2020 09:22 AM</td>
                <td class="text-center">1</td>
                <td>Yes</td>
                <td class="text-right">$2.99</td>
                <td class="text-right">-</td>
                <td class="text-right">$2.99</td>
                <td>Open</td>
                <td class="text-center">-</td>
              </tr>
              <tr>
                <td class="text-center">2</td>
                <td>EPS04020000023</td>
                <td>06/25/2020 08:22 AM</td>
                <td class="text-center">1</td>
                <td>Yes</td>
                <td class="text-right">$2.99</td>
                <td class="text-right">-</td>
                <td class="text-right">$2.99</td>
                <td>Paid</td>
                <td class="text-center"><a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a></td>
              </tr>
              <tr>
                <td class="text-center">3</td>
                <td>EPS04020000503</td>
                <td>06/25/2020 08:22 AM</td>
                <td class="text-center">1</td>
                <td>Yes</td>
                <td class="text-right">$2.99</td>
                <td class="text-right">-</td>
                <td class="text-right">$2.99</td>
                <td>Paid</td>
                <td class="text-center"><a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a></td>
              </tr>
              <tr>
                <td class="text-center">4</td>
                <td>EPS04020000203</td>
                <td>06/25/2020 08:22 AM</td>
                <td class="text-center">1</td>
                <td>Yes</td>
                <td class="text-right">$2.99</td>
                <td class="text-right">-</td>
                <td class="text-right">$2.99</td>
                <td>Paid</td>
                <td class="text-center"><a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a></td>
              </tr>
              <tr>
                <td class="text-center">5</td>
                <td>EPS04020000211</td>
                <td>06/25/2020 08:22 AM</td>
                <td class="text-center">1</td>
                <td>Yes</td>
                <td class="text-right">$2.99</td>
                <td class="text-right">-</td>
                <td class="text-right">$2.99</td>
                <td>Paid</td>
                <td class="text-center"><a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a></td>
              </tr>
            </tbody>
          </table>
          <div class="tab-footer-icons float-right">
            <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
            <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
            <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
            <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="nav-accountstab4" role="tabpanel" aria-labelledby="nav-home-tab">
        <div>
          <div class="tax-form-dropdown mb-2 float-left">
            <div class="show-dropdown">
              <label class="input-label">Form Type</label>
              <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  All
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#">Form W-2</a>
                  <a class="dropdown-item" href="#">Form 1099-MISC</a>
                  <a class="dropdown-item" href="#">Form 1099-NEC</a>
                  <a class="dropdown-item" href="#">Form 941</a>
                  <a class="dropdown-item" href="#">Form 941-X</a>
                </div>
              </div>
            </div>
          </div>
          <div class="float-right tax-form-dropdown mb-2">
            <label class="input-label">Tax Year</label>
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                2020
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#">2021</a>
                <a class="dropdown-item" href="#">2022</a>
                <a class="dropdown-item" href="#">2023</a>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-2">
            <div class="d-flex align-items-center">
              <span class="primary-color mr-3">Show</span>
              <div class="dropdown w-25">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  10
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#">20</a>
                  <a class="dropdown-item" href="#">30</a>
                  <a class="dropdown-item" href="#">40</a>
                </div>
              </div>
              <span class="primary-color ml-3">Entries</span>
            </div>
          </div>
          <div class="col-lg-8 ml-auto">
            <form class="form-inline my-2 my-lg-0 float-right">
              <input class="form-control mr-sm-2 search-bar" type="search" placeholder="Search" aria-label="Search">
            </form>
          </div>
        </div>

        <table class="table table-bordered mt-3 table-head-bg">
          <thead class="thead-light">
            <tr>
              <th scope="col" class="width-50 text-center">S.No.</th>
              <th scope="col">Employer/Payer</th>
              <th scope="col">Employee/Recipient</th>
              <th scope="col">Form Type</th>
              <th scope="col">Transmitted Date (EST)</th>
              <th scope="col">Postal Delivery</th>
              <th scope="col">State Filing</th>
              <th scope="col">Status</th>
              <th scope="col">Tax Year</th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">1</td>
              <td>Span Tech LLC<br /><span class="label-gray">01-6516545</span></td>
              <td>Jhon Mathew</td>
              <td>W-2</td>
              <td>06/25/2020 08:22 AM</td>
              <td>Yes</td>
              <td>Yes</td>
              <td>Transmitted</td>
              <td>2019</td>
              <td class="text-center">
                <a href="#" class="action-icon"><i class="fa fa-download" aria-hidden="true"></i></a>
                <a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a>
                <a href="#" class="action-icon"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>
              </td>
            </tr>
            <tr>
              <td class="text-center">2</td>
              <td>Google LLC<br /><span class="label-gray">01-6516541</span></td>
              <td>David Miller</td>
              <td>W-2</td>
              <td>06/25/2020 08:22 AM</td>
              <td>No</td>
              <td>No</td>
              <td>Transmitted</td>
              <td>2019</td>
              <td class="text-center">
                <a href="#" class="action-icon"><i class="fa fa-download" aria-hidden="true"></i></a>
                <a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a>
                <a href="#" class="action-icon"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>
              </td>
            </tr>
            <tr>
              <td class="text-center">2</td>
              <td>Radington Corporation <br /><span class="label-gray">01-6516562</span></td>
              <td>Alexander Daniel</td>
              <td>W-2</td>
              <td>06/25/2020 08:22 AM</td>
              <td>No</td>
              <td>No</td>
              <td>Transmitted</td>
              <td>2019</td>
              <td class="text-center">
                <a href="#" class="action-icon"><i class="fa fa-download" aria-hidden="true"></i></a>
                <a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a>
                <a href="#" class="action-icon"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="tab-footer-icons float-right">
          <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
          <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
          <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
          <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
        </div>
      </div>

      <div class="tab-pane fade show active" id="nav-accountstab5" role="tabpanel" aria-labelledby="nav-contact-tab">
        <div class="p-3 mx-auto">
          <div class="row">
            <div class="col-lg-12 text-right">
              <a data-toggle="modal" data-target="#test-account-popup" href="#"></a>
            </div>
          </div>

          <table class="width100 mt-0 action-table table table-bordered">
            <tbody>
              <tr>
                <td>Co-edit the account jointly with the user as an admin or manager</td>
                <td class="text-center"><a href="#" class="btn btn-primary btn-lg btn-primary-two btn-size-one mt-0 mb-0">Co-edit</a></td>
              </tr>
              <tr>
                <td>An email with password reset link will be sent to the user, through which they can reset their account password.</td>
                <td class="text-center"><a href="#" class="btn btn-primary btn-lg btn-primary-two btn-size-one mBot0 mTop0">Send password Reset Link</a></td>
              </tr>
              <tr>
                <td>By blocking the account, the user won't be able to access their ExpressEfile account.</td>
                <td class="text-center"><a href="#" class="btn btn-secondary btn-lg btn-primary-two btn-size-one mBot0 mTop0" data-toggle="modal" data-target="#block-account-popup">Block this account</a></td>
              </tr>
              <tr>
                <td>By logging off the user will be logged out of all devices. This can be used in case of security reasons.</td>
                <td class="text-center"><a href="#" class="btn btn-primary btn-lg btn-primary-two btn-size-one mBot0 mTop0" data-toggle="modal" data-target="#logoff-popup">Log off</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--log off Modal pop up -->
        <div class="modal fade popup-modal-style" id="logoff-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">Log off</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to log off this account?</p>
              </div>
              <div class="modal-footer text-center d-inline-block mt-3">
                <a href="" class="btn btn-outline-secondary mt-0 mr-3" data-dismiss="modal">No</a>
                <a href="" class="btn btn-primary btn-lg btn-primary-two mt-0">Yes</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Block account Modal pop up -->
        <div class="modal fade popup-modal-style" id="block-account-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">Block account</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to block this account?</p>
              </div>
              <div class="modal-footer text-center d-inline-block mt-3">
                <a href="" class="btn btn-outline-secondary mt-0 mr-3" data-dismiss="modal">No, don't block</a>
                <a href="" class="btn btn-primary btn-lg btn-primary-two mt-0">Yes, block</a>
              </div>
            </div>
          </div>
        </div>
        <!--Test account Modal pop up -->
        <div class="modal fade popup-modal-style" id="test-account-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">Mark as Test Account</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to mark this user as a test account?</p>
              </div>
              <div class="modal-footer text-center d-inline-block mt-3">
                <a href="" class="btn btn-outline-secondary mt-0 mr-3" data-dismiss="modal">No, don't mark</a>
                <a href="" class="btn btn-primary btn-lg btn-primary-two mt-0">Yes, mark</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-accountstab6" role="tabpanel" aria-labelledby="nav-home-tab">
        <div class="card p-3 mb-3 ng-star-inserted">
          <div class="w-100 mb-1">
            <div class="float-left">
              <label class="label-gray mb-0 mr-1"> Over All Experience Rating:</label>
              <span>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
              </span>
              <span class="font-italic ml-2">
                <label class="label-gray mb-0 mr-1">on</label>
                <span>02/01/2021 06:49 AM</span>
              </span>
            </div>
            <div class="float-right">
              <label class="label-gray mb-0 mr-1">Review on website:</label>
              <span class="ng-star-inserted"> Yes </span>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="w-100 mb-1">
            <label class="label-gray mb-0"> Liked features:</label>
            <span> -</span>
          </div>
          <div class="w-100 mb-1">
            <label class="label-gray mb-0"> Features to improve:</label>
            <span> - </span>
          </div>
          <div>
            <label class="label-gray mb-0"> Yours Testimonials:</label>
            <span> -</span>
          </div>
        </div>

        <div class="card p-3 mb-3 ng-star-inserted">
          <div class="w-100 mb-1">
            <div class="float-left">
              <label class="label-gray mb-0 mr-1"> Over All Experience Rating:</label>
              <span>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
                <i aria-hidden="true" class="primary-color fa fa-star"></i>
              </span>
              <span class="font-italic ml-2">
                <label class="label-gray mb-0">on</label>
                <span>02/01/2021 06:49 AM</span>
              </span>
            </div>
            <div class="float-right">
              <label class="label-gray mb-0">Review on website:</label>
              <span class="ng-star-inserted"> Yes </span>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="w-100 mb-1">
            <label class="label-gray mb-0"> Liked features:</label>
            <span> -</span>
          </div>
          <div class="w-100 mb-1">
            <label class="label-gray mb-0"> Features to improve:</label>
            <span> - </span>
          </div>
          <div>
            <label class="label-gray mb-0"> Yours Testimonials:</label>
            <span> -</span>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="nav-accountstab7" role="tabpanel" aria-labelledby="nav-home-tab">
        <div class="activtyLog-innerTab pricing-tab-version">
          <nav class="inner-tab-style">
            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-activtytab1" role="tab" aria-controls="nav-accountstab1" aria-selected="true">User Activity Log</a>
              <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-activtytab2" role="tab" aria-controls="nav-accountstab2" aria-selected="false">Support Activity Log</a>
              <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-activtytab3" role="tab" aria-controls="nav-accountstab2" aria-selected="false">Request Response Log</a>
            </div>
          </nav>
          <div class="tab-content pt-3 pb-0 px-3 px-sm-0" id="nav-tabContent">
            <div class="tab-pane fade active show" id="nav-activtytab1" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="card rounded-8 border p-3 pb-3">
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <form action="/action_page.php" class="date-picker-field">
                      <label class="input-label"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> Start Date</label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                  <div class="col-lg-2">
                    <form action="/action_page.php" class="date-picker-field">
                      <label class="input-label"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> End Date</label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                  <div class="col-lg-4 text-left">
                    <a href="#" class="btn btn-primary btn-lg btn-primary-two mt-0">Get Records</a>
                  </div>
                </div>
                <table class="table table-bordered table-head-bg mt-3">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col" class="width-50 text-center">S.No.</th>
                      <th scope="col">Description</th>
                      <th scope="col">Date/Time (EST)</th>
                      <th scope="col">IP Address</th>
                      <th scope="col">Browser</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">1</td>
                      <td>Sign out</td>
                      <td>11/19/2020 04:34 AM</td>
                      <td><a href="https://extreme-ip-lookup.com/49.205.81.64" target="_blank"><u>49.205.81.64</u></a></td>
                      <td>Browser (Chrome)</td>
                    </tr>
                    <tr>
                      <td class="text-center">2</td>
                      <td>Postal mailing opted for OPSC324A004</td>
                      <td>11/19/2020 03:48 AM</td>
                      <td><a class="text-underline" href="https://extreme-ip-lookup.com/49.205.81.64" target="_blank"><u>49.205.81.64</u></a></td>
                      <td>Browser (Chrome)</td>
                    </tr>
                    <tr>
                      <td class="text-center">3</td>
                      <td>Updated E-file information for Form 1099-MISC</td>
                      <td>11/19/2020 03:48 AM</td>
                      <td><a href="https://extreme-ip-lookup.com/49.205.81.64" target="_blank"><u>49.205.81.64</u></a></td>
                      <td>Browser (Chrome)</td>
                    </tr>
                    <tr>
                      <td class="text-center">4</td>
                      <td>Entered Form 1099-MISC information for John Carter Mr</td>
                      <td>11/19/2020 03:47 AM</td>
                      <td><a href="https://extreme-ip-lookup.com/49.205.81.64" target="_blank"><u>49.205.81.64</u></a></td>
                      <td>Browser (Chrome)</td>
                    </tr>
                    <tr>
                      <td class="text-center">5</td>
                      <td>Signed in using Google</td>
                      <td>11/19/2020 03:44 AM</td>
                      <td><a href="https://extreme-ip-lookup.com/49.205.81.64" target="_blank"><u>49.205.81.64</u></a></td>
                      <td>Browser (Chrome)</td>
                    </tr>
                    <tr>
                      <td class="text-center">6</td>
                      <td>Signed in using Google</td>
                      <td>11/03/2020 11:18 AM</td>
                      <td><a href="https://extreme-ip-lookup.com/49.205.81.64" target="_blank"><u>49.205.81.64</u></a></td>
                      <td>Browser (Chrome)</td>
                    </tr>
                  </tbody>
                </table>
                <div class="tab-footer-icons float-right text-right">
                  <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                  <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                  <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                  <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="nav-activtytab2" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="card rounded-8 border p-3 pb-3">
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <form action="/action_page.php" class="date-picker-field">
                      <label class="input-label"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> Start Date</label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                  <div class="col-lg-2">
                    <form action="/action_page.php" class="date-picker-field">
                      <label class="input-label"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> End Date</label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                  <div class="col-lg-4 text-left">
                    <a href="#" class="btn btn-primary btn-lg btn-primary-two mt-0">Get Records</a>
                  </div>
                </div>
                <table class="table table-bordered table-head-bg mt-3">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col" class="width-50 text-center">S.No.</th>
                      <th scope="col">Description</th>
                      <th scope="col">IP Address</th>
                      <th scope="col">Date/Time (EST)</th>
                      <th scope="col">Support Person</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center">1</td>
                      <td>William Smith cobrowsed</td>
                      <td><a href="https://extreme-ip-lookup.com/61.12.94.6" target="_blank"><u>61.12.94.6</u></a></td>
                      <td>03/27/2020 06:57 AM</td>
                      <td>William Smith</td>
                    </tr>

                    <tr>
                      <td class="text-center">2</td>
                      <td>Richard cobrowsed</td>
                      <td><a href="https://extreme-ip-lookup.com/81.102.940.64" target="_blank"><u>81.102.940.64</u></a></td>
                      <td>06/30/2020 09:57 AM</td>
                      <td>Richard</td>
                    </tr>
                  </tbody>
                </table>
                <div class="tab-footer-icons float-right text-right">
                  <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                  <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                  <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                  <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="nav-activtytab3" role="tabpanel" aria-labelledby="nav-home-tab">
              <div class="card rounded-8 border p-3 pb-3">
                <div class="row align-items-center">
                  <div class="col-lg-8 text-left">
                    <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Last 24 Hours Request Response Log</h2>
                  </div>
                  <div class="col-lg-4 text-right">
                    <a href="#" class="btn btn-primary btn-lg btn-primary-two mt-0">Get Records</a>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-lg-12">
                    <table class="table table-bordered table-head-bg">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col" class="width-50 text-center">S.No.</th>
                          <th scope="col">Request Url</th>
                          <th scope="col">Activity Date</th>
                          <th scope="col" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">1</td>
                          <td>/web/User/CheckUserExistsorNot</td>
                          <td>07/08/2020 08:04 AM</td>
                          <td class="text-center"><a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                        </tr>
                        <tr>
                          <td class="text-center">2</td>
                          <td>/web/paystub/GetRecentNotificationDetails</td>
                          <td>07/08/2020 07:34 AM</td>
                          <td class="text-center"><a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                        </tr>
                        <tr>
                          <td class="text-center">3</td>
                          <td>/web/User/BusinessDetails</td>
                          <td>07/08/2020 07:34 AM</td>
                          <td class="text-center"><a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                        </tr>
                        <tr>
                          <td class="text-center">4</td>
                          <td>/web/paystub/GetRecentNotificationDetails</td>
                          <td>07/08/2020 08:34 AM</td>
                          <td class="text-center"><a href="#" class="action-icon"><i class="fa fa-eye" aria-hidden="true"></i></a></td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="tab-footer-icons float-right">
                      <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                      <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                      <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                      <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                    </div>
                    <div class="alert alert-danger mt-4 w-50 mx-auto text-center" role="alert">
                      No request / response log
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="nav-accountstab8" role="tabpanel" aria-labelledby="nav-profile-tab">
        <div class="card rounded-8 border p-3">
          <h3 class="text-center primary-color"><b>Information Returns W2/1099</b></h3>
          <div class="row mt-2 mb-3">
            <div class="col-lg-1"></div>
            <div class="col-lg-5">
              <div class="card rounded-8 p-3 secondary-light-bg-color border-secondary">
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <div class="form-check">
                      <input class="form-check-input position-static" type="radio" name="blankRadio-btn1" id="blankRadio-btn1" value="option1" aria-label="..." checked />
                    </div>
                  </div>
                  <div class="col-lg-5">
                      <div class="">
                        <label class="label-gray font-14 mb-0">Group Name</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">Chicago CPA <span class="label-gray mb-0">(Default)</span></h3>
                      </div>
                      <div class="mt-2">
                        <label class="label-gray font-14 mb-0">Per Form Rate</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">$2.99</h3>
                      </div>
                  </div>
                  <div class="col-lg-5">
                      <div>
                        <label class="label-gray font-14 mb-0">Postal Mailing</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">$1.50</h3>
                      </div>
                      <div class="mt-2">
                        <label class="label-gray font-14 mb-0">Form Type</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">Information return W2/1099</h3>
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-5">
              <div class="card rounded-8 p-3 secondary-light-bg-color border-secondary">
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <div class="form-check">
                      <input class="form-check-input position-static" type="radio" name="blankRadio-btn2" id="blankRadio-btn2" value="option1" aria-label="..." />
                    </div>
                  </div>
                  <div class="col-lg-5">
                      <div class="">
                        <label class="label-gray font-14 mb-0">Group Name</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">South Carolina CPA</h3>
                      </div>
                      <div class="mt-2">
                        <label class="label-gray font-14 mb-0">Per Form Rate</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">$3.50</h3>
                      </div>
                  </div>
                  <div class="col-lg-5">
                      <div class="">
                        <label class="label-gray font-14 mb-0">Postal Mailing</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">$1.50</h3>
                      </div>
                      <div class="mt-2">
                        <label class="label-gray font-14 mb-0">Form Type</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">Information return W2/1099</h3>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-3" />
          <h3 class="text-center primary-color"><b>94X Series</b></h3>
          <div class="row mt-2">
            <div class="col-lg-1"></div>
            <div class="col-lg-5">
              <div class="card rounded-8 p-3 secondary-light-bg-color border-secondary">
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <div class="form-check">
                      <input class="form-check-input position-static" type="radio" name="blankRadio1" id="blankRadio1" value="option1" aria-label="..." />
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="">
                      <div>
                        <label class="label-gray font-14 mb-0">Group Name</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">Deluxe CPA</h3>
                      </div>
                      <div class="mt-2">
                        <label class="label-gray font-14 mb-0">Per Form Rate</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">$4.70</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="border-divider border-pos">
                      <div>
                        <label class="label-gray font-14 mb-0">Postal Mailing</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">$4.50</h3>
                      </div>
                      <div class="mt-2">
                        <label class="label-gray font-14 mb-0">Form Type</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">Information return W2/1099</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-5">
              <div class="card rounded-8 p-3 secondary-light-bg-color border-secondary">
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <div class="form-check">
                      <input class="form-check-input position-static" type="radio" name="blankRadio2" id="blankRadio2" value="option1" aria-label="..." checked>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="">
                      <div>
                        <label class="label-gray font-14 mb-0">Group Name</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">Chicago CPA <span class="label-gray mb-0">(Default)</span></h3>
                      </div>
                      <div class="mt-2">
                        <label class="label-gray font-14 mb-0">Per Form Rate</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">$4.00</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="border-divider">
                      <div class="">
                        <label class="label-gray font-14 mb-0">Postal Mailing</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">$1.50</h3>
                      </div>
                      <div class="mt-2">
                        <label class="label-gray font-14 mb-0">Form Type</label>
                        <h3 class="font-16 font-weight-semi-bold m-0">Information return W2/1099</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="nav-accountstab9" role="tabpanel" aria-labelledby="nav-credits-tab">
        <div class="activtyLog-innerTab pricing-tab-version">
          <nav class="inner-tab-style">
            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a class="nav-item nav-link active" id="nav-credit-detail-tab" data-toggle="tab" href="#nav-credit1" role="tab" aria-controls="nav-credit1" aria-selected="true">Credit Details</a>
              <a class="nav-item nav-link" id="nav-purchase-tab" data-toggle="tab" href="#nav-credit2" role="tab" aria-controls="nav-credit2" aria-selected="false">Credit Purchase History</a>
              <a class="nav-item nav-link" id="nav-usage-tab" data-toggle="tab" href="#nav-credit3" role="tab" aria-controls="nav-credit3" aria-selected="false">Credit Usage History</a>
              <a class="nav-item nav-link" id="nav-credit-tab" data-toggle="tab" href="#nav-credit4" role="tab" aria-controls="nav-credit4" aria-selected="false">Custom Credit Pricing</a>
            </div>
          </nav>
          <div id="nav-tabContent" class="tab-content pt-3 pb-0 px-3 px-sm-0">
            <div class="tab-pane fade active show" id="nav-credit1" role="tabpanel" aria-labelledby="nav-credits-tab">
              <div class="mt-3">
                <div>
                  <span class="primary-color font-18 font-weight-bold">Pending Credit Payments</span>
                  <span class="add-count-btn text-right float-right mb-3">
                    <a href="add-credite" class="btn btn-primary btn-lg btn-primary-two mt-0">Add More Credits</a>
                  </span>
                </div>
              </div>
              <div class="clearfix"></div>
              <div class="card rounded-8 border py-3 mb-3">
                <table class="w-100 text-center paystub-info">
                  <tr>
                    <td class="border-right px-2">
                      <div class="font-16">AE9861</div>
                      <div class="mt-1 font-14 label-gray">Order ID</div>
                    </td>
                    <td class="border-right px-2">
                      <div>10,Jul 2021 10:05 AM(EST)</div>
                      <div class="mt-1 font-14 label-gray">Purchased Date & Time</div>
                    </td>
                    <td class="border-right px-2">
                      <div class="font-16 some-div"><a href="#" class=""><u>06</u></a></div>
                      <div class="some-element position-absolute bg-white p-2 shadow-sm">
                        <div>
                          <p class="mb-1 font-14 font-weight-semibold text-left"> Information Returns (W-2,1099) </p>
                          <div class="font-14">
                            <span class="float-left label-gray">Federal Filing</span>
                            <span class="float-right">25 Credits</span>
                          </div>
                          <div class="clearfix mb-1"></div>
                          <div class="font-14">
                            <span class="float-left label-gray">State Filing</span>
                            <span class="float-right">10 Credits</span>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="mt-3">
                          <p class="mb-1 font-14 font-weight-semibold text-left"> 94x Series </p>
                          <div class="font-14">
                            <span class="float-left label-gray">Federal Filing</span>
                            <span class="float-right">05 Credits</span>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="mt-3">
                          <p class="mb-1 font-14 font-weight-semibold text-left"> Add-Ons </p>
                          <div class="font-14">
                            <span class="float-left label-gray">Postal Mailing</span>
                            <span class="float-right">10 Credits</span>
                          </div>
                          <div class="clearfix mb-1"></div>
                          <div class="font-14">
                            <span class="float-left label-gray">TIN Match</span>
                            <span class="float-right">10 Credits</span>
                          </div>
                        </div>
                        <!--<div>
    <p class="mb-1 font-14 font-weight-semibold text-left"> 94x Series </p>
    <div>
      <span class="float-left">Federal Filing</span>
      <span class="float-right">05 Credits</span>
    </div>
  </div>
  <div>
    <p class="mb-1 font-14 font-weight-semibold text-left"> Add-Ons </p>
    <div class="">
      <span class="float-left">Postal Mailing</span>
      <span class="float-right">10 Credits</span>
    </div>
    <div class="">
      <span class="float-left">TIN Match</span>
      <span class="float-right">10 Credits</span>
    </div>
  </div>-->
                      </div>
                      <div class="mt-1 font-14 label-gray">Credite</div>
                    </td>
                    <td class="border-right px-2">
                      <div class="font-16">$600.00</div>
                      <div class="mt-1 font-14 label-gray">Amount</div>
                    </td>
                    <td class="border-right px-2">
                      <div class="font-16">Payment Link</div>
                      <div class="mt-1 font-14 label-gray">Payment Mode</div>
                    </td>
                    <td class="border-right px-2">
                      <div class="font-16">
                        <a href="#">Resend Email1</a><br />
                        <span class="label-gray font-12 font-italic">(Valid upto Feb 25,2021 8:00pm EST)</span>
                      </div>
                      <div class="mt-1 font-14 label-gray">Payment Link</div>
                    </td>
                    <td class="px-2">
                      <a href="/payment-method-details" class="action-icon"><i class="fa fa-eye"></i></a>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="clearfix"></div>
              <h6 class="mt-4 primary-color font-18 font-weight-bold">Credits Activity & Balance</h6>
              <div class="row mb-3">
                <div class="col-lg-12">
                  <div class="card rounded-8 border">
                    <div class="card-header font-18 font-weight-semi-bold">
                      Information Returns (W-2,1099)
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-4 text-center border-right">
                          <h3 class="m-0 text-center">Total Credits</h3>
                          <div class="position-relative d-flex align-items-center pie-chart">
                            <span class="position-absolute chart-count font-12 text-center left-0 right-0 bottom-78">Total <br /><span class="font-weight-semibold primary-color font-16 font-500">200</span></span>
                            <ngx-charts-pie-chart [view]="view"
                                                  [scheme]="colorScheme1"
                                                  [results]="returns"
                                                  [gradient]="gradient"
                                                  [labels]="showLabels"
                                                  [doughnut]="isDoughnut"
                                                  (select)="onSelect($event)"
                                                  (activate)="onActivate($event)"
                                                  (deactivate)="onDeactivate($event)">
                            </ngx-charts-pie-chart>
                          </div>
                          <ul class="p-0 mb-0">
                            <li class="d-inline-block p-2 bg-success align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle mr-4">Used 125</li>
                            <li class="d-inline-block p-2 bg-warning align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle">Balance 75</li>
                          </ul>
                        </div>
                        <div class="col-md-4 text-center border-right">
                          <h3 class="m-0 text-center">Federal Filing </h3>
                          <div class="position-relative d-flex align-items-center pie-chart">
                            <span class="position-absolute chart-count font-12 text-center left-0 right-0 bottom-78">Total <br /><span class="font-weight-semibold primary-color font-16 font-500">100</span></span>
                            <ngx-charts-pie-chart [view]="view"
                                                  [scheme]="colorScheme"
                                                  [results]="returns1"
                                                  [gradient]="gradient"
                                                  [labels]="showLabels"
                                                  [doughnut]="isDoughnut"
                                                  (select)="onSelect($event)"
                                                  (activate)="onActivate($event)"
                                                  (deactivate)="onDeactivate($event)">
                            </ngx-charts-pie-chart>
                          </div>
                          <ul class="p-0 mb-0">
                            <li class="d-inline-block p-2 primary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle mr-4">Used 70</li>
                            <li class="d-inline-block p-2 secondary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle">Balance 30</li>
                          </ul>
                        </div>
                        <div class="col-md-4 text-center">
                          <h3 class="m-0 text-center">State Filing</h3>
                          <div class="position-relative d-flex align-items-center pie-chart">
                            <span class="position-absolute chart-count font-12 text-center left-0 right-0 bottom-78">Total <br /><span class="font-weight-semibold primary-color font-16 font-500">100</span></span>
                            <ngx-charts-pie-chart [view]="view"
                                                  [scheme]="colorScheme"
                                                  [results]="returns2"
                                                  [gradient]="gradient"
                                                  [labels]="showLabels"
                                                  [doughnut]="isDoughnut"
                                                  (select)="onSelect($event)"
                                                  (activate)="onActivate($event)"
                                                  (deactivate)="onDeactivate($event)">
                            </ngx-charts-pie-chart>
                          </div>
                          <ul class="p-0 mb-0">
                            <li class="d-inline-block p-2 primary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle mr-4">Used 55</li>
                            <li class="d-inline-block p-2 secondary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle">Balance 45</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-12">
                  <div class="card rounded-8 border">
                    <div class="card-header font-18 font-weight-semi-bold">
                      Add-Ons
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-3 text-center border-right">
                          <h3 class="m-0 text-center">Total Credits</h3>
                          <div class="position-relative d-flex align-items-center pie-chart">
                            <span class="position-absolute chart-count font-12 text-center left-0 right-0 bottom-78">Total <br /><span class="font-weight-semibold primary-color font-16 font-500">130</span></span>
                            <ngx-charts-pie-chart [view]="view"
                                                  [scheme]="colorScheme1"
                                                  [results]="addons"
                                                  [gradient]="gradient"
                                                  [labels]="showLabels"
                                                  [doughnut]="isDoughnut"
                                                  (select)="onSelect($event)"
                                                  (activate)="onActivate($event)"
                                                  (deactivate)="onDeactivate($event)">
                            </ngx-charts-pie-chart>
                          </div>
                          <ul class="p-0 mb-0">
                            <li class="d-inline-block p-2 bg-success align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle mr-4">Used 80</li>
                            <li class="d-inline-block p-2 bg-warning align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle">Balance 50</li>
                          </ul>
                        </div>
                        <div class="col-md-3 text-center border-right">
                          <h3 class="m-0 text-center">Postal Mailing </h3>
                          <div class="position-relative d-flex align-items-center pie-chart">
                            <span class="position-absolute chart-count font-12 text-center left-0 right-0 bottom-78">Total <br /><span class="font-weight-semibold primary-color font-16 font-500">50</span></span>
                            <ngx-charts-pie-chart [view]="view"
                                                  [scheme]="colorScheme"
                                                  [results]="addons1"
                                                  [gradient]="gradient"
                                                  [labels]="showLabels"
                                                  [doughnut]="isDoughnut"
                                                  (select)="onSelect($event)"
                                                  (activate)="onActivate($event)"
                                                  (deactivate)="onDeactivate($event)">
                            </ngx-charts-pie-chart>
                          </div>
                          <ul class="p-0 mb-0">
                            <li class="d-inline-block p-2 primary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle mr-4">Used 30</li>
                            <li class="d-inline-block p-2 secondary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle">Balance 20</li>
                          </ul>
                        </div>
                        <div class="col-md-3 text-center border-right">
                          <h3 class="m-0 text-center">Online Portal</h3>
                          <div class="position-relative d-flex align-items-center pie-chart">
                            <span class="position-absolute chart-count font-12 text-center left-0 right-0 bottom-78">Total <br /><span class="font-weight-semibold primary-color font-16 font-500">50</span></span>
                            <ngx-charts-pie-chart [view]="view"
                                                  [scheme]="colorScheme"
                                                  [results]="addons2"
                                                  [gradient]="gradient"
                                                  [labels]="showLabels"
                                                  [doughnut]="isDoughnut"
                                                  (select)="onSelect($event)"
                                                  (activate)="onActivate($event)"
                                                  (deactivate)="onDeactivate($event)">
                            </ngx-charts-pie-chart>
                          </div>
                          <ul class="p-0 mb-0">
                            <li class="d-inline-block p-2 primary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle mr-4">Used 30</li>
                            <li class="d-inline-block p-2 secondary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle">Balance 20</li>
                          </ul>
                        </div>
                        <div class="col-md-3 text-center">
                          <h3 class="m-0 text-center">TIN Match</h3>
                          <div class="position-relative d-flex align-items-center pie-chart">
                            <span class="position-absolute chart-count font-12 text-center left-0 right-0 bottom-78">Total <br /><span class="font-weight-semibold primary-color font-16 font-500">30</span></span>
                            <ngx-charts-pie-chart [view]="view"
                                                  [scheme]="colorScheme"
                                                  [results]="addons3"
                                                  [gradient]="gradient"
                                                  [labels]="showLabels"
                                                  [doughnut]="isDoughnut"
                                                  (select)="onSelect($event)"
                                                  (activate)="onActivate($event)"
                                                  (deactivate)="onDeactivate($event)">
                            </ngx-charts-pie-chart>
                          </div>
                          <ul class="p-0 mb-0">
                            <li class="d-inline-block p-2 primary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle mr-4">Used 20</li>
                            <li class="d-inline-block p-2 secondary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle">Balance 10</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-12">
                  <div class="card rounded-8 border">
                    <div class="card-header font-18 font-weight-semi-bold">
                      94x Series
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-md-6 text-center border-right">
                          <h3 class="m-0 text-center">Total Credits</h3>
                          <div class="position-relative d-flex align-items-center pie-chart">
                            <span class="position-absolute chart-count font-12 text-center left-0 right-0 bottom-78">Total <br /><span class="font-weight-semibold primary-color font-16 font-500">100</span></span>
                            <ngx-charts-pie-chart [view]="view"
                                                  [scheme]="colorScheme1"
                                                  [results]="series"
                                                  [gradient]="gradient"
                                                  [labels]="showLabels"
                                                  [doughnut]="isDoughnut"
                                                  (select)="onSelect($event)"
                                                  (activate)="onActivate($event)"
                                                  (deactivate)="onDeactivate($event)">
                            </ngx-charts-pie-chart>
                          </div>
                          <ul class="p-0 mb-0">
                            <li class="d-inline-block p-2 bg-success align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle mr-4">Used 70</li>
                            <li class="d-inline-block p-2 bg-warning align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle">Balance 30</li>
                          </ul>
                        </div>
                        <div class="col-md-6 text-center">
                          <h3 class="m-0 text-center">Federal Filing </h3>
                          <div class="position-relative d-flex align-items-center pie-chart">
                            <span class="position-absolute chart-count font-12 text-center left-0 right-0 bottom-78">Total <br /><span class="font-weight-semibold primary-color font-16 font-500">100</span></span>
                            <ngx-charts-pie-chart [view]="view"
                                                  [scheme]="colorScheme"
                                                  [results]="series1"
                                                  [gradient]="gradient"
                                                  [labels]="showLabels"
                                                  [doughnut]="isDoughnut"
                                                  (select)="onSelect($event)"
                                                  (activate)="onActivate($event)"
                                                  (deactivate)="onDeactivate($event)">
                            </ngx-charts-pie-chart>
                          </div>
                          <ul class="p-0 mb-0">
                            <li class="d-inline-block p-2 primary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle mr-4">Used 70</li>
                            <li class="d-inline-block p-2 secondary-bg-color align-bottom mr-1"></li>
                            <li class="d-inline-block align-middle">Balance 30</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="add-count-btn text-right">
                <a href="add-credite" class="btn btn-primary btn-lg btn-primary-two mt-0">Add More Credits</a>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-credit2" role="tabpanel" aria-labelledby="nav-credits-tab">
              <div class="mt-3">
                <h3 class="primary-color font-18 font-weight-bold">Pending Credit Payments</h3>
               <div class="card rounded-8 border py-3">
                 <table class="w-100 text-center paystub-info">
                   <tr>
                     <td class="border-right px-2">
                       <div class="font-16">AE9861</div>
                       <div class="mt-1 font-14 label-gray">Order ID</div>
                     </td>
                     <td class="border-right px-2">
                       <div>10,Jul 2021 10:05 AM(EST)</div>
                       <div class="mt-1 font-14 label-gray">Purchased Date & Time</div>
                     </td>
                     <td class="border-right px-2">
                       <div class="font-16"><a href="#"><u>06</u></a></div>
                       <div class="mt-1 font-14 label-gray">Credite</div>
                     </td>
                     <td class="border-right px-2">
                       <div class="font-16">$600.00</div>
                       <div class="mt-1 font-14 label-gray">Amount</div>
                     </td>
                     <td class="border-right px-2">
                       <div class="font-16">Payment Link</div>
                       <div class="mt-1 font-14 label-gray">Payment Mode</div>
                     </td>
                     <td class="border-right px-2">
                       <div class="font-16"><a href="#">Resend Email</a><br />
                       <span class="label-gray font-12 font-italic">(Valid upto Feb 25,2021 8:00pm EST)</span>
                       </div>
                       <div class="mt-1 font-14 label-gray">Payment Link</div>
                     </td>
                     <td class="px-2">
                       <a href="#" class="action-icon"><i class="fa fa-eye"></i></a>
                     </td>
                   </tr>
                 </table>
               </div>
              </div>
              <div class="mt-4">
                <div class="row align-items-center">
                  <div class="col-lg-4">
                    <div class="tax-form-dropdown">
                      <label class="input-label">Filter</label>
                      <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Filter by
                        </button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="#">Source - All</a>
                          <a class="dropdown-item" href="#">Source - Admin</a>
                          <a class="dropdown-item" href="#">Source - Secure</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <form action="/action_page.php" class="date-picker-field">
                      <label class="input-label"><i aria-hidden="true" class="fa fa-calendar-plus-o"></i> Start Date</label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                  <div class="col-lg-2">
                    <form action="/action_page.php" class="date-picker-field">
                      <label class="input-label"><i aria-hidden="true" class="fa fa-calendar-plus-o"></i> End Date</label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                  <div class="col-lg-4 text-left">
                    <a href="#" class="btn btn-primary btn-lg btn-primary-two mt-0">Get Records</a>
                  </div>
                </div>
                <div class="spacer20"></div>
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <div class="d-flex align-items-center">
                      <span class="primary-color mr-3">Show</span>
                      <div class="dropdown w-25">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          10
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" href="#">20</a>
                          <a class="dropdown-item" href="#">30</a>
                          <a class="dropdown-item" href="#">40</a>
                        </div>
                      </div>
                      <span class="primary-color ml-3">Entries</span>
                    </div>
                  </div>
                  <div class="col-lg-6 ml-auto">
                    <form class="form-inline my-2 my-lg-0 float-right">
                      <input class="form-control mr-sm-2 search-bar" type="search" placeholder="Search" aria-label="Search">
                    </form>
                  </div>
                </div>
                <table class="table table-bordered mt-3 table-head-bg">
                  <thead class="thead-light">
                    <tr>
                      <th>Order ID</th>
                      <th>Purchased Date & Time</th>
                      <th>Credite</th>
                      <th class="text-right">Amount</th>
                      <th>CC/Check Info</th>
                      <th>Source</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>SD1240</td>
                      <td>
                        08,Jan 2021<br>
                        <span class="label-gray font-12 font-italic">10:00 AM(EST)</span>
                      </td>
                      <td><a href="#"><u>10</u></a></td>
                      <td class="text-right">$ 3400.00</td>
                      <td><a id="nav-credit-detail-tab" data-toggle="tab" href="#nav-credit1" role="tab" aria-controls="nav-credit1" aria-selected="true" class="mr-2"><img src="images/card-visa.png" /></a>0982</td>
                      <td>Secure</td>
                      <td class="text-center">
                        <a href="#" class="action-icon"><i class="fa fa-eye"></i></a>
                        <a href="#" class="action-icon"><i class="fa fa-download"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>PK1634</td>
                      <td>
                        12,Dec 2020<br>
                        <span class="label-gray font-12 font-italic">08:05 AM(EST)</span>
                      </td>
                      <td><a href="#"><u>06</u></a></td>
                      <td class="text-right">$ 700.00</td>
                      <td><a id="nav-credit-detail-tab" data-toggle="tab" href="#nav-credit1" role="tab" aria-controls="nav-credit1" aria-selected="true" class="mr-2"><img src="images/card-master.png" /></a>5462</td>
                      <td>Admin</td>
                      <td class="text-center">
                        <a href="#" class="action-icon"><i class="fa fa-eye"></i></a>
                        <a href="#" class="action-icon"><i class="fa fa-download"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-credit3" role="tabpanel" aria-labelledby="nav-credits-tab">
              <div class="">
                <div class="row align-items-center">
                  <div class="col-lg-4">
                    <div class="tax-form-dropdown">
                      <div class="dropdown">
                        <label class="input-label">Start Date</label>
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Filter by
                        </button>
                        <div class="dropdown-menu">
                          <a class="dropdown-item" href="#">Source - All</a>
                          <a class="dropdown-item" href="#">Source - Admin</a>
                          <a class="dropdown-item" href="#">Source - Secure</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <form action="/action_page.php" class="date-picker-field">
                      <label class="input-label"><i aria-hidden="true" class="fa fa-calendar-plus-o"></i> Start Date</label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                  <div class="col-lg-2">
                    <form action="/action_page.php" class="date-picker-field">
                      <label class="input-label"><i aria-hidden="true" class="fa fa-calendar-plus-o"></i> End Date</label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                  <div class="col-lg-4 text-left">
                    <a href="#" class="btn btn-primary btn-lg btn-primary-two mt-0">Get Records</a>
                  </div>
                </div>
                <div class="spacer20"></div>
                <div class="row align-items-center">
                  <div class="col-lg-2">
                    <div class="d-flex align-items-center">
                      <span class="primary-color mr-3">Show</span>
                      <div class="dropdown w-25">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          10
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <a class="dropdown-item" href="#">20</a>
                          <a class="dropdown-item" href="#">30</a>
                          <a class="dropdown-item" href="#">40</a>
                        </div>
                      </div>
                      <span class="primary-color ml-3">Entries</span>
                    </div>
                  </div>
                  <div class="col-lg-6 ml-auto">
                    <form class="form-inline my-2 my-lg-0 float-right">
                      <input class="form-control mr-sm-2 search-bar" type="search" placeholder="Search" aria-label="Search">
                    </form>
                  </div>
                </div>
                <table class="table table-bordered mt-3 table-head-bg">
                  <thead class="thead-light">
                    <tr>
                      <th>Order ID</th>
                      <th>Purchased Date & Time</th>
                      <th>Credite</th>
                      <th>Form Used</th>
                      <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>PK1634</td>
                      <td>
                        28,Jun 2020<br>
                        <span class="label-gray font-12 font-italic">10:05 AM(EST)</span>
                      </td>
                      <td><a href="#"><u>11</u></a></td>
                      <td>W2</td>
                      <td class="text-center">
                        <a href="#" class="action-icon"><i class="fa fa-eye"></i></a>
                        <a href="#" class="action-icon"><i class="fa fa-download"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>AR3421</td>
                      <td>
                        30,Mar 2020<br>
                        <span class="label-gray font-12 font-italic">10:05 AM(EST)</span>
                      </td>
                      <td><a href="#"><u>16</u></a></td>
                      <td>1099 - MISC</td>
                      <td class="text-center">
                        <a href="#" class="action-icon"><i class="fa fa-eye"></i></a>
                        <a href="#" class="action-icon"><i class="fa fa-download"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane fade" id="nav-credit4" role="tabpanel" aria-labelledby="nav-credits-tab">
              <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Credit Pricing</h2>
              <p>The Pricing show below are default pricing, which is common for all users. To customize the pricing for this user, click edit and configure pricing Once you are done, save the pricing. This pricing will be consider only for this user.</p>
              <div class="row mb-3">
                <div class="col-lg-7">
                  <div class="card rounded-8 border">
                    <div class="card-header font-18 font-weight-semi-bold">
                      Information Returns (W-2,1099)
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-6 border-right">
                          <span class="label-gray font-16">Federal Filing</span>
                          <span class="float-right">
                            <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                          </span>
                          <div class="mt-2">
                            <table class="w-100">
                              <tbody>
                                <tr>
                                  <td>
                                    1 to 10 Credits
                                  </td>
                                  <td class="text-right">
                                    10% Discount
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-6">
                          <span class="label-gray font-16">State Filing</span>
                          <span class="float-right">
                            <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                          </span>
                          <div class="mt-2">
                            <table class="w-100">
                              <tbody>
                                <tr>
                                  <td>
                                    1 to 10 Credits
                                  </td>
                                  <td class="text-right">
                                    10% Discount
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="card rounded-8 border">
                    <div class="card-header font-18 font-weight-semi-bold">
                      94x Series
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-12">
                          <span class="label-gray font-16">Federal Filing</span>
                          <span class="float-right">
                            <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                          </span>
                          <div class="mt-2">
                            <table class="w-100">
                              <tbody>
                                <tr>
                                  <td>
                                    1 to 10 Credits
                                  </td>
                                  <td class="text-right">
                                    10% Discount
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="card rounded-8 border">
                    <div class="card-header font-18 font-weight-semi-bold">
                      Add-Ons
                    </div>
                    <div class="card-body p-3">
                      <div class="row">
                        <div class="col-4 border-right">
                          <span class="label-gray font-16">Online access portal</span>
                          <span class="float-right">
                            <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                          </span>
                          <div class="mt-2">
                            <table class="w-100">
                              <tbody>
                                <tr>
                                  <td>
                                    1 to 10 Credits
                                  </td>
                                  <td class="text-right">
                                    10% Discount
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-4 border-right">
                          <span class="label-gray font-16">TIN Match</span>
                          <span class="float-right">
                            <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                          </span>
                          <div class="mt-2">
                            <table class="w-100">
                              <tbody>
                                <tr>
                                  <td>
                                    1 to 10 Credits
                                  </td>
                                  <td class="text-right">
                                    10% Discount
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-4">
                          <span class="label-gray font-16">Postal Mailing</span>
                          <span class="float-right">
                            <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                          </span>
                          <div class="mt-2">
                            <table class="w-100">
                              <tbody>
                                <tr>
                                  <td>
                                    1 to 10 Credits
                                  </td>
                                  <td class="text-right">
                                    10% Discount
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
