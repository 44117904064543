<section class="container-fluid">
  <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Add Credits - <a href="/user-details" class="text-underline font-italic"><u>Mathew John Senior</u></a></h2>
  <div>
    <div class="row">
      <div class="col-lg-8 pr-0">
        <div class="border rounded-8 p-3">
          <h6 class="m-0 font-16 primary-color font-weight-bold">Information Returns (W-2, 1099-MISC, and 1099-NEC)</h6>
          <div class="shadow light-shadow card rounded-8 border p-3 mt-2 mb-4">
            <div class="row inner-box-width">
              <div class="col-12">
                <table class="w-100 order-summary-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="pb-3 w-30">Credits</th>
                      <th class="pb-3 text-right">Amount</th>
                      <th class="pb-3 text-center">Discount(%)</th>
                      <th class="pb-3 text-right w-20">Discounted Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Federal Filing
                      </td>
                      <td>
                        <mat-form-field class="example-form-field">
                          <mat-label>Enter here</mat-label>
                          <input matInput type="text">
                        </mat-form-field>
                        <span>X $1.49</span>
                      </td>
                      <td class="text-right">
                        $37.25
                      </td>
                      <td class="text-center">
                        (12.75%)
                      </td>
                      <td class="text-right">$32.5</td>
                    </tr>
                    <tr>
                      <td>
                        State Filing
                      </td>
                      <td>
                        <mat-form-field class="example-form-field">
                          <mat-label>Enter here</mat-label>
                          <input matInput type="text">
                        </mat-form-field>
                        <span>X $1.49</span>
                      </td>
                      <td class="text-right">
                        $29.8
                      </td>
                      <td class="text-center">
                        (12.75%)
                      </td>
                      <td class="text-right">$32.5</td>
                    </tr>
                    <tr><td colspan="5"><hr /></td></tr>
                    <tr>
                      <td colspan="4" class="text-left">
                        <b>Net Total</b>
                      </td>
                      <td class="text-right primary-color font-18">
                        <b>$58.50</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <h5 class="m-0 font-16 primary-color font-weight-bold">94x Series (941, 940, and 941-X)</h5>
          <div class="shadow light-shadow card rounded-8 border p-3 mt-2 mb-4">
            <div class="row inner-box-width">
              <div class="col-12">
                <table class="w-100 order-summary-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="pb-3 w-30">Credits</th>
                      <th class="pb-3 text-right">Amount</th>
                      <th class="pb-3 text-center">Discount(%)</th>
                      <th class="pb-3 text-right w-20">Discounted Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Federal Filing
                      </td>
                      <td>
                        <mat-form-field class="example-form-field">
                          <mat-label>Enter here</mat-label>
                          <input matInput type="text">
                        </mat-form-field>
                        <span>X $1.49</span>
                      </td>
                      <td class="text-right">
                        $44.7
                      </td>
                      <td class="text-center">
                        (19.4%)
                      </td>
                      <td class="text-right">$36.00</td>
                    </tr>
                    <tr><td colspan="5"><hr /></td></tr>
                    <tr>
                      <td colspan="4" class="text-left">
                        <b>Net Total</b>
                      </td>
                      <td class="text-right primary-color font-18">
                        <b>$36.00</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <h5 class="m-0 font-16 primary-color font-weight-bold">Add-Ons (Postal mailing, Online postal access, and TIN matching)</h5>
          <div class="shadow light-shadow card rounded-8 border p-3 mt-2 mb-4">
            <div class="row inner-box-width">
              <div class="col-12">
                <table class="w-100 order-summary-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="pb-3 w-30">Credits</th>
                      <th class="pb-3 text-right">Amount</th>
                      <th class="pb-3 text-center">Discount(%)</th>
                      <th class="pb-3 text-right w-20">Discounted Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Postal Mailing
                      </td>
                      <td>
                        <mat-form-field class="example-form-field">
                          <mat-label>Enter here</mat-label>
                          <input matInput type="text">
                        </mat-form-field>
                        <span>X $1.5</span>
                      <td class="text-right">
                        $72.00
                      </td>
                      <td class="text-center">
                        (20%)
                      </td>
                      <td class="text-right pb-2">$48.00</td>
                    </tr>
                    <tr>
                      <td>
                        Oneline Portal Access
                      </td>
                      <td>
                        <mat-form-field class="example-form-field">
                          <mat-label>Enter here</mat-label>
                          <input matInput type="text">
                        </mat-form-field>
                        <span>X $1.80</span>
                      </td>
                      <td class="text-right">
                        $12.5
                      </td>
                      <td class="text-center">
                        (60%)
                      </td>
                      <td class="text-right pb-2">$5.00</td>
                    </tr>
                    <tr>
                      <td class="pb-2">
                        TIN Match
                      </td>
                      <td>
                        <mat-form-field class="example-form-field">
                          <mat-label>Enter here</mat-label>
                          <input matInput type="text">
                        </mat-form-field>
                        <span>X $1.00</span>
                      </td>
                      <td class="text-right">
                        $10.00
                      </td>
                      <td class="text-center">
                        (30%)
                      </td>
                      <td class="text-right">$7.00</td>
                    </tr>
                    <tr><td colspan="5"><hr /></td></tr>
                    <tr>
                      <td colspan="4" class="text-left">
                        <b>Net Total</b>
                      </td>
                      <td class="text-right primary-color font-18">
                        <b>$60.00</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="m-0 position-sticky top-185 shadow light-shadow card rounded-8 border p-3 mb-4">
          <h6 class="m-0 font-16 primary-color font-weight-bold">
            Order Summary
          </h6>
          <div class="order-summary mt-2 p-2">
            <table class="w-100 order-summary-table">
              <thead>
                <tr>
                  <th class="pb-3">Description</th>
                  <th class="text-right pb-3">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="mb-2">
                    Information Returns
                  </td>
                  <td class="text-right pb-2 font-16 font-weight-semibold">
                    $58.50
                  </td>
                </tr>
                <tr>
                  <td class="pb-2">
                    94x Series
                  </td>
                  <td class="text-right pb-2 font-16 font-weight-semibold">
                    $36.5
                  </td>
                </tr>
                <tr>
                  <td>
                    Add-Ons
                  </td>
                  <td class="text-right font-16 font-weight-semibold">$60.00</td>
                </tr>
                <tr><td colspan="2"><hr /></td></tr>
                <tr>
                  <td class="text-left"><b>Total Amount</b></td>
                  <td class="text-right primary-color font-18"><b>$154.5</b></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-3">
            <a href="payment-method" class="btn btn-primary btn-lg btn-primary-two btn-size-one mBot0 mTop0">Process the Order</a>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <div class="mt-3">
    <a href="/user-details" class="btn btn-outline-primary btn-back mt-0 float-left"><i aria-hidden="true" class="fa fa-angle-left"></i> Back</a>
  </div>
  <div class="clearfix"></div>
</section>
