<section>
  <h2 class="mt-0 mb-1 font-22 primary-color font-weight-bold float-left">Add Discount Code</h2>
  <a class="btn btn-outline-secondary btn-back mt-0 float-right"><i  aria-hidden="true" class="fa fa-angle-left"></i> Back</a>
  <div class="clearfix"></div>
  <div class="row mt-3 mb-3">
    <div class="col-md-8">
    <form  novalidate="" autocomplete="off" class="ng-untouched ng-pristine ng-invalid">
      <div class="row">
      <div class="col-lg-4 text-right pr-0">
        <label  class="label-gray mr-1 mb-0"> Discount Code<sup  class="text-danger">*</sup>: </label></div>
      <div class="col-lg-8">
        <mat-form-field class="w-100 mat-no-label">
          <input matInput type="text">
        </mat-form-field>
      </div>

      <div class="col-lg-4 text-right pr-0"><label class="label-gray mr-1 mb-0"> Description:</label></div>
      <div class="form-inline col-lg-8">
        <mat-form-field class="w-100 mat-no-label">
          <input matInput type="text">
        </mat-form-field>
      </div>

      <div class="col-lg-4 text-right pr-0"><label  class="label-gray mr-1 mb-0"> Devices:</label></div>
      <div class="col-lg-8">
        <span class="font-weight-bold">Web Only</span>
        <div class="spacer20"></div>
      </div>
      
      <div  class="col-lg-4 text-right pr-0">
        <label  class="label-gray mr-1 mb-0"> Discount Type<sup  class="text-danger">*</sup>:</label>
      </div>
      <div class="col-lg-8">
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="1" class="mr-3">Percentage</mat-radio-button>
          <mat-radio-button value="2">Fixed Amount</mat-radio-button>
        </mat-radio-group>
        <div class="spacer10"></div>
      </div>
    </div><!---->
      <div class="row">
        <div class="col-lg-4 text-right pr-0">
        <label  class="label-gray mr-1 mb-0"> Applies to<sup  class="text-danger">*</sup>:</label>
       </div>
        <div class="col-lg-8">
          <mat-checkbox class="mr-3">Information return Form W-2/1099</mat-checkbox>
          <mat-checkbox class="mr-3">Form 94x Series</mat-checkbox>
          <mat-checkbox>ACA Forms</mat-checkbox>
          <div class="spacer10"></div>
        </div>
     </div><!----><!---->
      <div class="row">
      <div class="col-lg-4 text-right pr-0"><label class="label-gray mr-1 mb-0"> Minimum Requirements<sup  class="text-danger">*</sup>:</label></div>

      <div class="col-lg-8">
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="1" class="mr-3">None</mat-radio-button>
          <mat-radio-button value="2" class="mr-3">Minimum Purchase Amount($)</mat-radio-button>
          <mat-radio-button value="2">Minimum Quantity</mat-radio-button>
        </mat-radio-group>
        <div class="spacer10"></div>
      </div>
        </div>

      <div class="row">
      <div class="col-lg-4 text-right pr-0"><label class="label-gray mr-1 mb-0"> User Eligibility<sup class="text-danger">*</sup>:</label></div>

      <div class="col-lg-8">
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="1" class="mr-3">All User</mat-radio-button>
          <mat-radio-button value="2" class="mr-3">Specific Group</mat-radio-button>
          <mat-radio-button value="2">Specific Users</mat-radio-button>
        </mat-radio-group>
        <div class="spacer10"></div>
      </div>
        </div>
      <div class="spacer10"></div>
      <div class="row">
      <div class="col-lg-4 text-right pr-0"><label  class="label-gray mr-1 mb-0"> Discount Count Limit<sup  class="text-danger">*</sup>: </label></div>

      <div class="col-lg-8">
        <mat-radio-group aria-label="Select an option">
          <mat-radio-button value="1" class="mr-3">Unlimited</mat-radio-button>
          <mat-radio-button value="2">Limited</mat-radio-button>
        </mat-radio-group>
      </div>
       </div><!---->

      <div class="row">
      <div class="col-lg-4 text-right pr-0"><label  class="label-gray mr-1 mb-0 mt-1"> Valid from<sup  class="text-danger">*</sup>: </label></div>
      <div  class="col-lg-8 d-flex">
        <div class="width-200">
          <mat-form-field class="w-100 mat-no-label">
            <input matInput type="text">
          </mat-form-field>
        </div><label class="label-gray mr-1 mb-0 mt-1"> to </label>
        <div class="width-200 mat-no-label">
        <mat-form-field class="w-100">
         <input matInput type="text">
        </mat-form-field>
        </div>
        </div>
        </div><div  class="clearfix"></div>
      </form>
    </div>


  <div class="col-md-4">
    <div class="card rounded-8 p-2 position-sticky top-200 height-250">
    <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Summary</h2>
    <div class="text-center ng-star-inserted">
      <div class="spacer10"></div>
      Please start entering the discount code information to see a summary.
      </div><!----><!---->
      </div></div>
  <div class="clearfix"></div>
    </div>

  <div><button  tabindex="19" class="btn btn-outline-secondary btn-back mt-0"> Cancel </button><button  tabindex="20" type="submit" class="btn btn-primary float-right" style="cursor: pointer; float: right;"> Save <!----></button></div>
  <div  class="clearfix"></div>
</section>
