<div class="row main-tab-style m-0">
  <div class="col-md-2 mb-3">
    <ul class="nav nav-pills flex-column outer-nav h-100" id="myTab" role="tablist">
      <li class="nav-item mb-3">
        <a class="nav-link active" id="default-group-pricing" data-toggle="tab" href="#default-group-maintab1" role="tab" aria-controls="default-group" aria-selected="true">Default Pricing</a>
      </li>
      <li class="nav-item mb-3">
        <a class="nav-link" id="custom-group-pricing" data-toggle="tab" href="#custom-group-maintab1" role="tab" aria-controls="custom-group" aria-selected="true">Custom Pricing</a>
      </li>
      <li class="nav-item mb-3">
        <a class="nav-link" id="discount-tab" data-toggle="tab" href="#discount-maintab2" role="tab" aria-controls="discount" aria-selected="false">Discount</a>
      </li>
      <li class="nav-item mb-3">
        <a class="nav-link" id="credit-pricing" data-toggle="tab" href="#credit-group-pricing" role="tab" aria-controls="credit" aria-selected="false">Credit Pricing</a>
      </li>
    </ul>
  </div>
  <!-- /.col-md-4 -->
  <div class="col-md-10 pl-0">
    <div class="tab-content main-outer-tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="default-group-maintab1" role="tabpanel" aria-labelledby="default-group-tab">
        <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Default Groups</h2>
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="card rounded-8 border">
              <div class="card-header font-20 font-weight-semi-bold">
                Information Returns
                <a href="" data-toggle="modal" data-target="#editgroup-popup" class="float-right primary-color"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
              </div>
              <div class="card-body p-3">
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Group Name:</span><span class="font-weight-semi-bold">Information Returns</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Per Form Rate:</span><span class="font-weight-semi-bold">$4.00</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">No.of Users:</span><span class="font-weight-semi-bold">268</span>
                </div>

                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Form Type:</span><span class="font-weight-semi-bold">Information Return W2/1099</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Postal Mailing:</span><span class="font-weight-semi-bold">$1.50</span>
                </div>
                <div class="text-right">
                  <!--<a href="" class="btn btn-primary btn-lg btn-primary-two" data-toggle="modal" data-target="#adduser-popup">Add User</a>-->
                  <a href="page5a.html" class="btn btn-secondary btn-sm btn-primary-two mt-0">View</a>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="card rounded-8 border">
              <div class="card-header font-20 font-weight-semi-bold">
                94x Series
                <a href="" data-toggle="modal" data-target="#editgroup-popup" class="float-right primary-color"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
              </div>
              <div class="card-body p-3">
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Group Name:</span><span class="font-weight-semi-bold">94x Series</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Per Form Rate:</span><span class="font-weight-semi-bold">$6.00</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">No.of Users:</span><span class="font-weight-semi-bold">200</span>
                </div>

                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Form Type:</span><span class="font-weight-semi-bold">94x Series</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Postal Mailing:</span><span class="font-weight-semi-bold">$3.50</span>
                </div>
                <div class="text-right">
                  <!--<a href="" class="btn btn-primary btn-lg btn-primary-two" data-toggle="modal" data-target="#adduser-popup">Add User</a>-->
                  <a href="page5a.html" class="btn btn-secondary btn-sm mt-0">View</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4  mb-3">
            <div class="card rounded-8 border">
              <div class="card-header font-20 font-weight-semi-bold">
                94x Series
                <a href="" data-toggle="modal" data-target="#editgroup-popup" class="float-right primary-color"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
              </div>
              <div class="card-body p-3">
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Group Name:</span><span class="font-weight-semi-bold">94x Series</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Per Form Rate:</span><span class="font-weight-semi-bold">$5.00</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">No.of Users:</span><span class="font-weight-semi-bold">180</span>
                </div>

                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Form Type:</span><span class="font-weight-semi-bold">ACA Forms</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Postal Mailing:</span><span class="font-weight-semi-bold">$1.50</span>
                </div>
                <div class="text-right">
                  <!--<a href="" class="btn btn-primary btn-lg btn-primary-two" data-toggle="modal" data-target="#adduser-popup">Add User</a>-->
                  <a href="page5a.html" class="btn btn-secondary btn-sm mt-0">View</a>
                </div>
              </div>
            </div>
          </div>

        </div>



      </div>

      <div class="tab-pane fade" id="custom-group-maintab1" role="tabpanel" aria-labelledby="custom-group-tab">

        <div class="row align-items-top mb-2">
          <div class="col-lg-6">
            <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Custom Group</h2>
          </div>
          <div class="col-lg-6">
            <div class="add-count-btn text-right">
              <a href="" class="btn btn-primary btn-lg btn-primary-two mt-0" data-toggle="modal" data-target="#addgroup-popup">Add New Group</a>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="card rounded-8 border">
              <div class="card-header font-20 font-weight-semi-bold">
                Mathew Shippings
                <div class="float-right dropdown w-auto verical-menu-dropdown">
                  <a href="javascript:void(0);" class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-ellipsis-v primary-color font-20" aria-hidden="true"></i></a>
                  <div class="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuButton" x-placement="right-start">
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#editgroup-popup">Edit Group</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#deletegroup-popup">Delete Group</a>
                  </div>
                </div>
              </div>
              <div class="card-body p-3">
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Group Name:</span><span class="font-weight-semi-bold">Information Returns</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Per Form Rate:</span><span class="font-weight-semi-bold">$3.99</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">No.of Users:</span><span class="font-weight-semi-bold">120</span>
                </div>

                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Form Type:</span><span class="font-weight-semi-bold">Information Return W2/1099</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Postal Mailing:</span><span class="font-weight-semi-bold">$1.50</span>
                </div>
                <div class="">
                  <a href="#" class="btn btn-primary btn-sm float-left" data-toggle="modal" data-target="#adduser-popup">Add User</a>
                  <a href="page5a.html" class="btn btn-secondary btn-sm mt-0 float-right">View</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="card rounded-8 border">
              <div class="card-header font-20 font-weight-semi-bold">
                DG Accounts
                <div class="float-right dropdown w-auto verical-menu-dropdown">
                  <a href="javascript:void(0);" class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-ellipsis-v primary-color font-20" aria-hidden="true"></i></a>
                  <div class="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuButton" x-placement="right-start">
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#editgroup-popup">Edit Group</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#deletegroup-popup">Delete Group</a>
                  </div>
                </div>
              </div>
              <div class="card-body p-3">
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Group Name:</span><span class="font-weight-semi-bold">94x Series</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Per Form Rate:</span><span class="font-weight-semi-bold">$2.99</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">No.of Users:</span><span class="font-weight-semi-bold">80</span>
                </div>

                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Form Type:</span><span class="font-weight-semi-bold">94x Series</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Postal Mailing:</span><span class="font-weight-semi-bold">$3.50</span>
                </div>
                <div class="">
                  <a href="#" class="btn btn-primary btn-sm float-left" data-toggle="modal" data-target="#adduser-popup">Add User</a>
                  <a href="page5a.html" class="btn btn-secondary btn-sm mt-0 float-right">View</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4 mb-3">
            <div class="card rounded-8 border">
              <div class="card-header font-20 font-weight-semi-bold">
                Chicago CPA
                <div class="float-right dropdown w-auto verical-menu-dropdown">
                  <a href="javascript:void(0);" class="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-ellipsis-v primary-color font-20" aria-hidden="true"></i></a>
                  <div class="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuButton" x-placement="right-start">
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#editgroup-popup">Edit Group</a>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#deletegroup-popup">Delete Group</a>
                  </div>
                </div>
              </div>
              <div class="card-body p-3">
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Group Name:</span><span class="font-weight-semi-bold">94x Series</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Per Form Rate:</span><span class="font-weight-semi-bold">$2.99</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">No.of Users:</span><span class="font-weight-semi-bold">80</span>
                </div>

                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Form Type:</span><span class="font-weight-semi-bold">94x Series</span>
                </div>
                <div class="mb-2">
                  <span class="label-gray mr-1 mb-0">Postal Mailing:</span><span class="font-weight-semi-bold">$3.50</span>
                </div>
                <div class="">
                  <a href="#" class="btn btn-primary btn-sm float-left" data-toggle="modal" data-target="#adduser-popup">Add User</a>
                  <a href="page5a.html" class="btn btn-secondary btn-sm mt-0 float-right">View</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Model Popup Add User-->
      <div class="modal fade popup-modal-style" id="adduser-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">Add Users to Information Returns</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card rounded-8 border p-3 mb-3">
                <div class="row align-items-center">
                  <div class="col-lg-5 mb-2">
                    <span class="label-gray mr-1 mb-0">Group Name:</span><span class="font-weight-semi-bold">Information Returns</span>
                  </div>
                  <div class="col-lg-3 mb-2">
                    <span class="label-gray mr-1 mb-0">Per Form Rate:</span><span class="font-weight-semi-bold">$4.00</span>
                  </div>
                  <div class="col-lg-3 mb-2">
                    <span class="label-gray mr-1 mb-0">No.of Users:</span><span class="font-weight-semi-bold">268</span>
                  </div>

                  <div class="col-lg-5">
                    <span class="label-gray mr-1 mb-0">Form Type:</span><span class="font-weight-semi-bold">Information Return W2/1099</span>
                  </div>
                  <div class="col-lg-3">
                    <span class="label-gray mr-1 mb-0">Postal Mailing:</span><span class="font-weight-semi-bold">$1.50</span>
                  </div>
                  <div class="col-lg-3">
                    <span class="label-gray mr-1 mb-0">State filing Rate:</span><span class="font-weight-semi-bold">$1.50</span>
                  </div>
                </div>
              </div>


              <div class="row mt-3 align-items-center justify-content-center">
                <div class="col-lg-6">
                  <div class="show-dropdown">
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Search by Contact Name/User Email
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="#">Kelly Harry</a>
                        <a class="dropdown-item" href="#"> Kelly Rock</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <table class="table table-bordered mTop10 table-head-bg">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">User Detail</th>
                      <th scope="col">Current Group</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-left">
                        Kelly Harry<br />
                        <span class="label-gray">kellyharry@gmail.com</span>
                      </td>
                      <td>
                        DG Accounts<br />
                        <span class="label-gray">Form Type:Information Return W2/1099</span>
                      </td>
                      <td>
                        <a href="#"> <i class="fa fa-refresh mr-2" aria-hidden="true"></i> Switch to this group</a>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        Kelly Rock<br />
                        <span class="label-gray">kellyro@gmail.com</span>
                      </td>
                      <td>
                        Information Returns<br />
                        <span class="label-gray">Form Type:Information Return W2/1099</span>
                      </td>
                      <td>
                        <div class="primary-color"><i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i> Already added to this group</div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
            <div class="modal-footer text-center d-inline-block mt-3">
              <a href="" class="btn btn-outline-secondary mt-0" data-dismiss="modal">Close</a>
            </div>
          </div>
        </div>

      </div>
      <!--Model Popup Add Group-->
      <div class="modal fade popup-modal-style" id="addgroup-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">Add New Group</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row fieldwidth100 mb-3">
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">Group Name<sup class="redcolor">*</sup></label>
                    <input class="form-control mr-sm-2" placeholder="Enter Group Name">
                  </form>
                </div>
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">Per Form Rate<sup class="redcolor">*</sup></label>
                    <input class="form-control mr-sm-2" placeholder="Enter Form Rate">
                  </form>
                </div>
              </div>
              <div class="row fieldwidth100">
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">Postal Mailing Rate<sup class="redcolor">*</sup></label>
                    <input class="form-control mr-sm-2" placeholder="Enter Postal Mailing Rate">
                  </form>
                </div>
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">State Filing Rate<sup class="redcolor">*</sup></label>
                    <input class="form-control mr-sm-2" placeholder="EnterSstate Mailing Rate">
                  </form>
                </div>
              </div>


              <div class="row mt-3 align-items-center">
                <div class="col-lg-12">
                  <div class="show-dropdown">
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Form Type<sup class="text-danger">*</sup>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="#">Information Return W2/1099</a>
                        <a class="dropdown-item" href="#">94x Series</a>
                        <a class="dropdown-item" href="#">ACA Forms</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer text-center d-inline-block mt-3">
              <a href="" class="btn btn-outline-secondary mt-0 mr-3" data-dismiss="modal">Cancel</a>
              <a href="" class="btn btn-primary btn-lg btn-primary-two mt-0">Save & Create Group</a>
            </div>
          </div>
        </div>

      </div>

      <!--Model Popup Edit Group-->
      <div class="modal fade popup-modal-style" id="editgroup-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">Edit Group - DG Accounts</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row fieldwidth100 mb-3">
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">Group Name<sup class="redcolor">*</sup></label>
                    <input class="form-control mr-sm-2" placeholder="Enter Group Name" value="DG Accounts">
                  </form>
                </div>
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">Per Form Rate<sup class="redcolor">*</sup></label>
                    <input class="form-control mr-sm-2" placeholder="Enter Form Rate" value="$4.00">
                  </form>
                </div>
              </div>
              <div class="row fieldwidth100">
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">Postal Mailing Rate<sup class="redcolor">*</sup></label>
                    <input class="form-control mr-sm-2" placeholder="Enter Postal Mailing Rate" value="$1.50">
                  </form>
                </div>
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">State Filing Rate<sup class="redcolor">*</sup></label>
                    <input class="form-control mr-sm-2" placeholder="EnterSstate Mailing Rate" value="$1.50">
                  </form>
                </div>
              </div>


              <div class="row mt-3 align-items-center">
                <div class="col-lg-12">
                  <div class="show-dropdown">
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Information Return W2/1099
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="#">Information Return W2/1099</a>
                        <a class="dropdown-item" href="#">94x Series</a>
                        <a class="dropdown-item" href="#">ACA Forms</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="modal-footer text-center d-inline-block mt-3">
              <a href="" class="btn btn-outline-secondary mt-0 mr-3" data-dismiss="modal">Cancel</a>
              <a href="" class="btn btn-primary btn-lg btn-primary-two mt-0">Save Group</a>
            </div>
          </div>
        </div>

      </div>
      <!--Model Popup delete Group-->
      <div class="modal fade popup-modal-style" id="deletegroup-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">Delete this Group</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>By deleting this group, Users in this group will be joined in the default information returns group.</p>
              <p>Are you sure you want to delete this group?</p>

            </div>
            <div class="modal-footer text-center d-inline-block mt-3">
              <a href="" class="btn btn-outline-secondary mt-0 mr-3" data-dismiss="modal">No, I don't want to delete</a>
              <a href="" class="btn btn-primary btn-lg btn-primary-two mt-0">Yes, I want to delete</a>
            </div>
          </div>
        </div>

      </div>

      <div class="tab-pane fade" id="discount-maintab2" role="tabpanel" aria-labelledby="discount-tab">
        <div class="pricing-tab-version mTop0">
          <div class="add-discount-div" *ngIf="displayDiscount">
            <app-add-discount></app-add-discount>
          </div>
          <div class="discount-list" *ngIf="!displayDiscount">
            <div class="row align-items-top">
              <div class="col-lg-6">
                <h2 class="mt-0 mb-1 font-22 primary-color font-weight-bold">Discount List</h2>
              </div>
              <div class="col-lg-6">
                <div class="add-count-btn text-right">
                  <a href="javascript:Void(0);" class="btn btn-primary btn-lg btn-primary-two mt-0" (click)="addDiscount()">Add Discount Code</a>
                </div>
              </div>
            </div>

            <nav class="inner-tab-style">
              <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-Pricingtab1" role="tab" aria-controls="nav-accountstab1" aria-selected="true">Active Codes</a>
                <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-Pricingtab2" role="tab" aria-controls="nav-accountstab2" aria-selected="false">Inactive Codes</a>
              </div>
            </nav>
            <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
              <div class="tab-pane fade active show" id="nav-Pricingtab1" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="card rounded-8 border mb-3">
                  <div class="card-body px-3 py-2">
                    <div class="row align-items-center">
                      <div class="col-lg-9">
                        <div>
                          <span class="mr-2 font-weight-semi-bold">Discount Code: <span class="primary-color font-20">BLACKFRIDAY</span></span>
                          <span class="label-gray font-italic">2/100 used </span>
                        </div>
                        <div class="d-inline-block mt-2">
                          <span class="label-gray mr-1">Valid from</span><span class="font-weight-semi-bold mr-2">01/25/2021</span>
                          <span class="label-gray mr-1">to</span><span class="font-weight-semi-bold">01/25/2021</span>
                        </div>
                      </div>
                      <div class="col-lg-3 text-right">
                        <a href="javascript:void(0)" class="action-icon view-discount"><i class="fa fa-eye" aria-hidden="true"></i></a>
                        <a href="javascript:void(0)" class="action-icon edit-discount"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
                        <a href="javascript:void(0)" class="action-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-2">
                    <ul class="list-inline mb-0 list-circle">
                      <li class="list-inline-item">Web only</li>
                      <li class="list-inline-item">25% offer all forms</li>
                      <li class="list-inline-item">For every user</li>
                      <li class="list-inline-item">Limit 100 uses, one per user</li>
                    </ul>
                  </div>
                </div>

                <div class="card rounded-8 border mb-3">
                  <div class="card-body px-3 py-2">
                    <div class="row align-items-center">
                      <div class="col-lg-9">
                        <div>
                          <span class="mr-2 font-weight-semi-bold">Discount Code: <span class="primary-color font-20">First Sale</span></span>
                          <span class="label-gray font-italic">2/100 used </span>
                        </div>
                        <div class="d-inline-block mt-2">
                          <span class="label-gray mr-1">Valid from</span><span class="font-weight-semi-bold mr-2">01/25/2021</span>
                          <span class="label-gray mr-1">to</span><span class="font-weight-semi-bold">01/25/2021</span>
                        </div>
                      </div>

                      <div class="col-lg-3 text-right">
                        <a href="javascript:void(0)" class="action-icon view-discount"><i class="fa fa-eye" aria-hidden="true"></i></a>
                        <a href="javascript:void(0)" class="action-icon edit-discount"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
                        <a href="javascript:void(0)" class="action-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-2">
                    <ul class="list-inline mb-0 list-circle">
                      <li class="list-inline-item">Web only</li>
                      <li class="list-inline-item">$3.99 offer Form W-2</li>
                      <li class="list-inline-item">For every user</li>
                      <li class="list-inline-item">Limit one per user</li>
                    </ul>
                  </div>
                </div>

                <div class="card rounded-8 border mb-3">
                  <div class="card-body px-3 py-2">
                    <div class="row align-items-center">
                      <div class="col-lg-9">
                        <div>
                          <span class="mr-2 font-weight-semi-bold">Discount Code: <span class="primary-color font-20">Returncredit</span></span>
                          <span class="label-gray font-italic">0/01 used </span>
                        </div>
                        <div class="d-inline-block mt-2">
                          <span class="label-gray mr-1">Valid from</span><span class="font-weight-semi-bold mr-2">01/25/2021</span>
                          <span class="label-gray mr-1">to</span><span class="font-weight-semi-bold">01/25/2021</span>
                        </div>
                      </div>
                      <div class="col-lg-3 text-right">
                        <a href="javascript:void(0)" class="action-icon view-discount"><i class="fa fa-eye" aria-hidden="true"></i></a>
                        <a href="javascript:void(0)" class="action-icon edit-discount"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
                        <a href="javascript:void(0)" class="action-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-2">
                    <ul class="list-inline mb-0 list-circle">
                      <li class="list-inline-item">Web only</li>
                      <li class="list-inline-item">$5.99 offer all forms</li>
                      <li class="list-inline-item">For specific user</li>
                      <li class="list-inline-item">Allow balance discount carryover</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="nav-Pricingtab2" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div class="card rounded-8 border mb-3 position-relative overflow-hidden">
                  <div class="card-body px-3 py-2">
                    <div class="row align-items-center">
                      <div class="col-lg-9">
                        <div>
                          <span class="mr-2 font-weight-semi-bold">Discount Code: <span class="font-20">Returncredit</span></span>
                          <span class="label-gray font-italic">2/100 used </span>
                        </div>
                        <div class="d-inline-block mt-2">
                          <span class="label-gray mr-1">Valid from</span><span class="font-weight-semi-bold mr-2">01/25/2021</span>
                          <span class="label-gray mr-1">to</span><span class="font-weight-semi-bold">01/25/2021</span>
                        </div>
                      </div>
                      <div class="col-lg-3 text-right pr-5">
                        <a href="javascript:void(0)" class="action-icon view-discount"><i class="fa fa-eye" aria-hidden="true"></i></a>
                      </div>
                      <div class="expire-div"><span class="expire-text">EXPRIED</span></div>
                    </div>
                  </div>
                  <div class="card-footer py-2">
                    <ul class="list-inline mb-0 list-circle">
                      <li class="list-inline-item">Web only</li>
                      <li class="list-inline-item">10% offer all forms</li>
                      <li class="list-inline-item">For every user</li>
                      <li class="list-inline-item">Limit 100 uses, one per user</li>
                    </ul>
                  </div>
                </div>

                <div class="card rounded-8 border mb-3 position-relative overflow-hidden">
                  <div class="card-body px-3 py-2">
                    <div class="row align-items-center">
                      <div class="col-lg-9">
                        <div>
                          <span class="mr-2 font-weight-semi-bold">Discount Code: <span class="font-20">94xOffer</span></span>
                          <span class="label-gray font-italic">2/100 used </span>
                        </div>
                        <div class="d-inline-block mt-2">
                          <span class="label-gray mr-1">Valid from</span><span class="font-weight-semi-bold mr-2">01/25/2021</span>
                          <span class="label-gray mr-1">to</span><span class="font-weight-semi-bold">01/25/2021</span>
                        </div>
                      </div>
                      <div class="col-lg-3 text-right pr-5">
                        <a href="javascript:void(0)" class="action-icon view-discount"><i class="fa fa-eye" aria-hidden="true"></i></a>
                      </div>
                      <div class="expire-div"><span class="expire-text">EXPRIED</span></div>
                    </div>
                  </div>
                  <div class="card-footer py-2">
                    <ul class="list-inline mb-0 list-circle">
                      <li class="list-inline-item">Web only</li>
                      <li class="list-inline-item">25% offer on Form941</li>
                      <li class="list-inline-item">For every user</li>
                      <li class="list-inline-item">Limit one per user</li>
                    </ul>
                  </div>
                </div>

                <div class="tab-footer-icons float-right">
                  <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                  <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                  <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                  <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="discount-view-detail" style="display:none">
          <div class="row">
            <div class="col-lg-12 mb-2">
              <a class="float-left back-discount mr-2" href="javascript:void(0);"><i class="fa fa-arrow-left font-20" aria-hidden="true"></i></a>
              <h2 class="float-left mt-0 mb-3 font-22 primary-color font-weight-bold">Order Details by Discount Code</h2>

              <div class="clearfix"></div>
            </div>
          </div>
          <table class="table table-bordered mt-4 table-head-bg">
            <thead class="thead-light">
              <tr>
                <th scope="col" class="width-50 text-center">S.No.</th>
                <th scope="col">Order Id</th>
                <th scope="col" class="text-right">Discount Amount</th>
                <th scope="col">Discount Name</th>
                <th scope="col">CC Number</th>
                <th scope="col">Paid Date(EST)</th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">1</td>
                <td>EEF0330A023</td>
                <td class="text-right">$10.00</td>
                <td>
                  BLACKFRIDAY
                </td>
                <td>
                  111
                </td>
                <td>11/13/2020 02:07 AM</td>
              </tr>

              <tr>
                <td class="text-center">2</td>
                <td>EEF0330A022</td>
                <td class="text-right">$8.00</td>
                <td>
                  BLACKFRIDAY
                </td>
                <td>
                  111
                </td>
                <td>11/13/2020 02:07 AM</td>
              </tr>

              <tr>
                <td class="text-center">3</td>
                <td>EEF0330A021</td>
                <td class="text-right">$1.00</td>
                <td>
                  BLACKFRIDAY
                </td>
                <td>
                  111
                </td>
                <td>11/13/2020 02:07 AM</td>
              </tr>
              <tr>
                <td class="text-center">4</td>
                <td>EEF0330A020</td>
                <td class="text-right">$10.00</td>
                <td>
                  BLACKFRIDAY
                </td>
                <td>
                  111
                </td>
                <td>11/13/2020 02:07 AM</td>
              </tr>

              <tr>
                <td class="text-center">5</td>
                <td>EEF0330A019</td>
                <td class="text-right">$5.00</td>
                <td>
                  BLACKFRIDAY
                </td>
                <td>
                  111
                </td>
                <td>11/13/2020 02:07 AM</td>
              </tr>


            </tbody>
          </table>
          <div class="tab-footer-icons float-right">
            <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
            <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
            <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
            <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
          </div>
        </div>

        <div class="modal fade popup-modal-style" id="addcount-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle"><span class="secondary-color">[5024]</span> Save Discount Code</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row fieldwidth100">
                  <div class="col-lg-6">
                    <form class="form-inline my-2 my-lg-0">
                      <label class="label">Discount Code<sup class="redcolor">*</sup></label>
                      <input class="form-control mr-sm-2" type="search" placeholder="Enter Code" aria-label="Search">
                    </form>
                  </div>
                  <div class="col-lg-6">
                    <form class="form-inline my-2 my-lg-0">
                      <label class="label">Description</label>
                      <input class="form-control mr-sm-2" type="search" placeholder="Enter here" aria-label="Search">
                    </form>
                  </div>
                </div>


                <div class="row mt-3 align-items-center">
                  <div class="col-lg-12">
                    <label class="label">
                      Devices
                    </label>
                    <div class="radio-btn-selector">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input text-dark" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked>
                        <label class="custom-control-label" for="inlineRadio1">Web Only</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                        <label class="custom-control-label" for="inlineRadio2">Mobile Only</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3">
                        <label class="custom-control-label" for="inlineRadio3">Both Web and Mobile</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mt-3">
                    <label class="label">
                      Discount Types<sup class="text-danger">*</sup>
                    </label>
                    <div class="radio-btn-selector">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input text-dark" type="radio" name="discounttype" id="discounttypeRadio1" value="option1" checked>
                        <label class="custom-control-label" for="discounttypeRadio1">Percentage</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" type="radio" name="discounttype" id="discounttypeRadio2" value="option2">
                        <label class="custom-control-label" for="discounttypeRadio2">Fixed Amount</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" type="radio" name="discounttype" id="discounttypeRadio3" value="option3">
                        <label class="custom-control-label" for="discounttypeRadio3">Buy X get Y</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 mt-3">
                    <label class="label mb-0">
                      Discount Value<sup class="text-danger">*</sup>
                    </label>
                    <input class="form-control w-100 pt-0" type="search" placeholder="Enter here" aria-label="Search">
                  </div>
                  <div class="col-lg-12 mt-3">
                    <label class="label">
                      Applies to<sup class="text-danger">*</sup>
                    </label>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customCheck1">
                      <label class="custom-control-label black-color" for="customCheck1" checked="">Information return Form W-2/1099</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customCheck2">
                      <label class="custom-control-label black-color" for="customCheck2">Form 94x Series</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customCheck3">
                      <label class="custom-control-label black-color" for="customCheck3">ACA Forms</label>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-3">
                    <label class="label">
                      Information Return
                    </label>
                    <div class="radio-btn-selector">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input text-dark" type="radio" name="inforeturn" id="inforeturnRadio1" value="option1" checked>
                        <label class="custom-control-label" for="inforeturnRadio1">All Forms</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" type="radio" name="inforeturn" id="inforeturnRadio2" value="option2">
                        <label class="custom-control-label" for="inforeturnRadio2">Specific Forms</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-3">
                    <label class="label">
                      Minimum Requirements
                    </label>
                    <div class="radio-btn-selector">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input text-dark" type="radio" name="minreq" id="minreqRadio1" value="option1" checked>
                        <label class="custom-control-label" for="minreqRadio1">None</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" type="radio" name="minreq" id="minreqRadio2" value="option2">
                        <label class="custom-control-label" for="minreqRadio2">Minimum Purchase amount($)</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" type="radio" name="minreq" id="minreqRadio3" value="option3">
                        <label class="custom-control-label" for="minreqRadio3">Minimum quantity</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-3">
                    <label class="label">
                      User Eligibility
                    </label>
                    <div class="radio-btn-selector">
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input text-dark" type="radio" name="usereligibility" id="usereligibilityRadio1" value="option1" checked>
                        <label class="custom-control-label" for="usereligibilityRadio1">Every user</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" type="radio" name="usereligibility" id="usereligibilityRadio2" value="option2">
                        <label class="custom-control-label" for="usereligibilityRadio2">Specific Group</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input class="custom-control-input" type="radio" name="usereligibility" id="usereligibilityRadio3" value="option3">
                        <label class="custom-control-label" for="usereligibilityRadio3">Specific Users</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 mt-3">
                    <label class="label">
                      Usage Limits
                    </label>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="usageCheck1">
                      <label class="custom-control-label black-color" for="usageCheck1" checked=""> Limit no of times this discount can be used in total</label>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <input class="form-control w-100" type="search" placeholder="Enter here" aria-label="Search" value="100">
                      </div>
                      <div class="col-6">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="usageCheck2">
                          <label class="custom-control-label black-color" for="usageCheck2" checked="">Limit to one use per user</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-lg-12">
                    <label class="label">
                      Active Dates<sup class="text-danger">*</sup>
                    </label>
                  </div>
                  <div class="col-lg-6">
                    <form action="/action_page.php" class="date-picker-field">
                      <label><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> Start Date<sup class="redcolor">*</sup></label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                  <div class="col-lg-6">
                    <form action="/action_page.php" class="date-picker-field">
                      <label><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> End Date<sup class="redcolor">*</sup></label>
                      <input type="date" id="birthday" name="birthday">
                    </form>
                  </div>
                </div>


              </div>
              <div class="modal-footer text-center d-inline-block mt-3">
                <a href="" class="btn btn-outline-secondary mt-0 mr-3" data-dismiss="modal">Cancel</a>
                <a href="" class="btn btn-primary btn-lg btn-primary-two mt-0" data-dismiss="modal">Save</a>
              </div>
            </div>
          </div>
        </div>
        <!--Edit Discount-->
        <div class="edit-discount-detail" style="display:none">
          <div class="row">
            <div class="col-lg-12 mb-2">
              <a class="float-left back-edit-discount mr-2" href="javascript:void(0);"><i class="fa fa-arrow-left font-20" aria-hidden="true"></i></a>
              <h2 class="float-left mt-0 mb-3 font-22 primary-color font-weight-bold">Edit Discount Code</h2>

              <div class="clearfix"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9">
              <div class="row fieldwidth100">
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">Discount Code<sup class="redcolor">*</sup></label>
                    <input class="form-control mr-sm-2" type="search" placeholder="Enter Code" aria-label="Search" value="BLACKFRIDAY">
                  </form>
                </div>
                <div class="col-lg-6">
                  <form class="form-inline my-2 my-lg-0">
                    <label class="label">Description</label>
                    <input class="form-control mr-sm-2" type="search" placeholder="Enter here" aria-label="Search" value="Year End Offer">
                  </form>
                </div>
              </div>


              <div class="row mt-3 align-items-center">
                <div class="col-lg-12">
                  <label class="label">
                    Devices
                  </label>
                  <div class="radio-btn-selector">

                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input text-dark" type="radio" name="editinlineRadioOptions" id="editdeviceRadio1" value="option1" checked>
                      <label class="custom-control-label" for="editdeviceRadio1">Web Only</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="editinlineRadioOptions" id="editdeviceRadio2" value="option2">
                      <label class="custom-control-label" for="editdeviceRadio2">Mobile Only</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="editinlineRadioOptions" id="editdeviceRadio3" value="option3">
                      <label class="custom-control-label" for="editdeviceRadio3">Both Web and Mobile</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mt-3">
                  <label class="label">
                    Discount Types<sup class="text-danger">*</sup>
                  </label>
                  <div class="radio-btn-selector">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input text-dark" type="radio" name="editdiscounttype" id="editdiscounttypeRadio1" value="option1" checked>
                      <label class="custom-control-label" for="editdiscounttypeRadio1">Percentage</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="editdiscounttype" id="editdiscounttypeRadio2" value="option2">
                      <label class="custom-control-label" for="editdiscounttypeRadio2">Fixed Amount</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="editdiscounttype" id="editdiscounttypeRadio3" value="option3">
                      <label class="custom-control-label" for="editdiscounttypeRadio3">Buy X get Y</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mt-3">
                  <label class="label mb-0">
                    Discount Value<sup class="text-danger">*</sup>
                  </label>
                  <input class="form-control w-100 pt-0" type="search" placeholder="Enter here" aria-label="Search" value="25%">
                </div>
                <div class="col-lg-12 mt-3">
                  <label class="label">
                    Applies to<sup class="text-danger">*</sup>
                  </label>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="editcustomCheck1">
                    <label class="custom-control-label black-color" for="editcustomCheck1" checked="">Information return Form W-2/1099</label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="editcustomCheck2">
                    <label class="custom-control-label black-color" for="editcustomCheck2">Form 94x Series</label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="editcustomCheck3">
                    <label class="custom-control-label black-color" for="editcustomCheck3">ACA Forms</label>
                  </div>
                </div>
                <div class="col-lg-12 mt-3">
                  <label class="label">
                    Information Return
                  </label>
                  <div class="radio-btn-selector">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input text-dark" type="radio" name="editinforeturn" id="editinforeturnRadio1" value="option1" checked>
                      <label class="custom-control-label" for="editinforeturnRadio1">All Forms</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="editinforeturn" id="editinforeturnRadio2" value="option2">
                      <label class="custom-control-label" for="editinforeturnRadio2">Specific Forms</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mt-3">
                  <label class="label">
                    Minimum Requirements
                  </label>
                  <div class="radio-btn-selector">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input text-dark" type="radio" name="editminreq" id="editminreqRadio1" value="option1" checked>
                      <label class="custom-control-label" for="editminreqRadio1">None</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="editminreq" id="editminreqRadio2" value="option2">
                      <label class="custom-control-label" for="editminreqRadio2">Minimum Purchase amount($)</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="editminreq" id="editminreqRadio3" value="option3">
                      <label class="custom-control-label" for="editminreqRadio3">Minimum quantity</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mt-3">
                  <label class="label">
                    User Eligibility
                  </label>
                  <div class="radio-btn-selector">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input text-dark" type="radio" name="editusereligibility" id="editusereligibilityRadio1" value="option1" checked>
                      <label class="custom-control-label" for="editusereligibilityRadio1">Every user</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="editusereligibility" id="editusereligibilityRadio2" value="option2">
                      <label class="custom-control-label" for="editusereligibilityRadio2">Specific Group</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input class="custom-control-input" type="radio" name="editusereligibility" id="editusereligibilityRadio3" value="option3">
                      <label class="custom-control-label" for="editusereligibilityRadio3">Specific Users</label>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mt-3">
                  <label class="label">
                    Usage Limits
                  </label>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="editusageCheck1">
                    <label class="custom-control-label black-color" for="editusageCheck1" checked=""> Limit no of times this discount can be used in total</label>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <input class="form-control w-100" type="search" placeholder="Enter here" aria-label="Search" value="100">
                    </div>
                    <div class="col-6">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="editusageCheck2">
                        <label class="custom-control-label black-color" for="editusageCheck2" checked="">Limit to one use per user</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-lg-12">
                  <label class="label">
                    Active Dates<sup class="text-danger">*</sup>
                  </label>
                </div>
                <div class="col-lg-6">
                  <form action="/action_page.php" class="date-picker-field">
                    <label><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> Start Date<sup class="redcolor">*</sup></label>
                    <input type="date" id="birthday" name="birthday">
                  </form>
                </div>
                <div class="col-lg-6">
                  <form action="/action_page.php" class="date-picker-field">
                    <label><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> End Date<sup class="redcolor">*</sup></label>
                    <input type="date" id="birthday" name="birthday">
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="card p-2">
                <h2 class="mb-0 mt-0 mb-3 font-22 primary-color font-weight-bold">Summary</h2>
                <hr />
                <h3 class="primary-color font-weight-semi-bold ml-2"> BLACKFRIDAY</h3>
                <ul>
                  <li>
                    Web only discount
                  </li>
                  <li>25% off all forms</li>
                  <li>
                    No minimum requirements
                  </li>
                  <li>
                    For every user
                  </li>
                  <li>
                    Limit of 100 uses, one per user
                  </li>
                  <li>
                    Active from today until Jan 31,21
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <a href="javascript:void(0)" class="btn btn-outline-secondary mt-0 mr-3 back-edit-discount">Cancel</a>
              <a href="javascript:void(0)" class="btn btn-primary btn-lg btn-primary-two mt-0 back-edit-discount float-right">Save Discount code</a>
            </div>
          </div>
        </div>

      </div>

      <div class="tab-pane fade" id="credit-group-pricing" role="tabpanel" aria-labelledby="credit-pricing">
        <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Credit Pricing</h2>
        <div class="row mb-3">
          <div class="col-lg-7">
            <div class="card rounded-8 border">
              <div class="card-header font-18 font-weight-semi-bold">
                Information Returns (W-2,1099)
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-6 border-right">
                    <span class="label-gray font-16">Federal Filing</span>
                    <span class="float-right">
                      <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                    </span>
                    <div class="mt-2">
                      <table class="w-100">
                        <tbody>
                          <tr>
                            <td>
                              1 to 10 Credits
                            </td>
                            <td class="text-right">
                              10% Discount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-6">
                    <span class="label-gray font-16">State Filing</span>
                    <span class="float-right">
                      <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                    </span>
                    <div class="mt-2">
                      <table class="w-100">
                        <tbody>
                          <tr>
                            <td>
                              1 to 10 Credits
                            </td>
                            <td class="text-right">
                              10% Discount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="card rounded-8 border">
              <div class="card-header font-18 font-weight-semi-bold">
                94x Series
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-12">
                    <span class="label-gray font-16">Federal Filing</span>
                    <span class="float-right">
                      <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                    </span>
                    <div class="mt-2">
                      <table class="w-100">
                        <tbody>
                          <tr>
                            <td>
                              1 to 10 Credits
                            </td>
                            <td class="text-right">
                              10% Discount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="card rounded-8 border">
              <div class="card-header font-18 font-weight-semi-bold">
                Add-Ons
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-4 border-right">
                    <span class="label-gray font-16">Online access portal</span>
                    <span class="float-right">
                      <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                    </span>
                    <div class="mt-2">
                      <table class="w-100">
                        <tbody>
                          <tr>
                            <td>
                              1 to 10 Credits
                            </td>
                            <td class="text-right">
                              10% Discount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-4 border-right">
                    <span class="label-gray font-16">TIN Match</span>
                    <span class="float-right">
                      <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                    </span>
                    <div class="mt-2">
                      <table class="w-100">
                        <tbody>
                          <tr>
                            <td>
                              1 to 10 Credits
                            </td>
                            <td class="text-right">
                              10% Discount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-4">
                    <span class="label-gray font-16">Postal Mailing</span>
                    <span class="float-right">
                      <a href="#" class="primary-color font-18"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></a>
                    </span>
                    <div class="mt-2">
                      <table class="w-100">
                        <tbody>
                          <tr>
                            <td>
                              1 to 10 Credits
                            </td>
                            <td class="text-right">
                              10% Discount
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
  <!-- /.col-md-8 -->
</div>
