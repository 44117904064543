<section class="container-fluid">
  <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Prepaid Credits-Payment</h2>
  <div class="row mb-3 justify-content-center">
    <div class="col-lg-5">
      <h6 class="mb-2 font-16 primary-color font-weight-bold">
        Order Summary
      </h6>
      <div class="order-summary mt-2 p-2">
        <div>
          <div class="d-inline-block">
            <span class="label-gray mr-1">Name:</span>
            <span class="font-weight-semi-bold">Mathew John Junior</span>
          </div>
          <div class="d-inline-block float-right">
            <span class="label-gray mr-1">Email:</span>
            <span class="font-weight-semi-bold">mathewjohn@gmail.com</span>
          </div>
        </div>
        <div><hr /></div>
        <table class="w-100 order-summary-table">
          <thead>
            <tr>
              <th class="pb-3">Description</th>
              <th class="text-right pb-3">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="mb-2">
                Information Returns
              </td>
              <td class="text-right pb-2 font-16 font-weight-semibold">
                $58.50
              </td>
            </tr>
            <tr>
              <td class="pb-2">
                94x Series
              </td>
              <td class="text-right pb-2 font-16 font-weight-semibold">
                $36.5
              </td>
            </tr>
            <tr>
              <td>
                Add-Ons
              </td>
              <td class="text-right font-16 font-weight-semibold">$60.00</td>
            </tr>
            <tr><td colspan="2"><hr /></td></tr>
            <tr>
              <td class="text-left"><b>Total Amount</b></td>
              <td class="text-right primary-color font-18"><b>$154.5</b></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <h2 class="mt-0 mb-4 font-22 primary-color font-weight-bold">
    Send Payment Link
    <a href="#" class="btn btn-primary btn-sm float-right">Payment Status Pending</a>
  </h2>
  <div class="card rounded-8 border mb-3 position-relative overflow-hidden">
    <div class="card-body px-3 py-2">
      <div class="row align-items-center mr-5 min-height-70">
        <div class="col-lg-12">
          <table class="w-100 text-center">
            <tr>
              <td>
                <span class="label-gray mr-1">Order ID: </span>
                <span class="font-weight-semi-bold"> SK1233 </span>
              </td>
              <td>
                <span class="label-gray mr-1">Link Valid from: </span><span class="font-weight-semi-bold">02/15/2021 <span class="label-gray font-12 font-italic">(09:45 AM EST)</span> </span>
              </td>
              <td>
                <span class="label-gray mr-1">Link Valid Upto: </span><span class="font-weight-semi-bold">02/16/2021 <span class="label-gray font-12 font-italic">(09:45 AM EST)</span> </span>
              </td>
              <td>
                <a href="#" class="btn btn-primary btn-sm float-right">Re-send Payment Link</a>
              </td>
            </tr>
          </table>
        </div>
        <div class="expire-div"><span class="expire-text">EXPRIED</span></div>
      </div>
    </div>
  </div>
  <div class="card rounded-8 border mb-3 position-relative overflow-hidden">
    <div class="card-body px-3 py-2">
      <div class="row align-items-center mr-5 min-height-70">
        <div class="col-lg-12">
          <table class="w-100 text-center">
            <tr>
              <td>
                <span class="label-gray mr-1">Order ID: </span>
                <span class="font-weight-semi-bold"> RS2135 </span>
              </td>
              <td>
                <span class="label-gray mr-1">Link Valid from: </span><span class="font-weight-semi-bold">02/10/2021 <span class="label-gray font-12 font-italic">(04:05 PM EST)</span> </span>
              </td>
              <td>
                <span class="label-gray mr-1">Link Valid Upto: </span><span class="font-weight-semi-bold">02/11/2021 <span class="label-gray font-12 font-italic">(04:05 PM EST)</span> </span>
              </td>
              <td>
                <a href="#" class="btn btn-primary btn-sm float-right disabled">Re-send Payment Link</a>
              </td>
            </tr>
          </table>
        </div>
        <div class="expire-div"><span class="expire-text">EXPRIED</span></div>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <a href="/user-details" class="btn btn-outline-primary btn-back mt-0 float-left">Back to Prepaid Credits</a>
  </div>
  <div class="clearfix"></div>
</section>








