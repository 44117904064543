<div class="d-flex align-items-center justify-content-center flex-column vh-100 secure-login-form">
  <div class="w-30">
    <div class="card shadow border p-4 text-center rounded-8">
      <div class="mb-2 text-center">
        <img src="images/logo.png" alt="Logo" class="img-fluid min-width-150" />
      </div>
      <h2 class="mt-2 primary-color font-22 font-weight-bold">Sign in to your account</h2>
      <label class="d-block mb-0 text-left primary-color font-14">Email</label>
      <form class="form-inline">
        <input class="form-control d-block w-100 text-left rounded-0 p-0 border-top-0 border-left-0 border-right-0 border-bottom shadow-none" type="text" placeholder="kate@gmail.com" aria-label="Search">
      </form>

      <label class="d-block mb-0 text-left primary-color mt-3">Password</label>
      <form class="form-inline">
        <input class="form-control d-block w-100 text-left rounded-0 p-0 border-top-0 border-left-0 border-right-0 border-bottom shadow-none" type="text" placeholder="**********" aria-label="Search">
      </form>

      <div class="mt-3 pt-2">
        <a href="page1.html" class="btn btn-primary btn-lg primary-bg-color shadow-none text-decoration-none border-0 font-16 py-2 px-5 text-white rounded-30">Continue</a>
        <a href="page2.html" class="text-center mt-2 d-block">Forget Password?</a>
        <p class="mt-3"><em>For any assistance, please contact the admin</em></p>
      </div>
      <div class="mb-2 text-center">
        <a href="#" class="mr-3"> <i class="fa fa-envelope primary-color mr-1" aria-hidden="true"></i>admin@expressefile.com</a>
        <a href="#"><i class="fa fa-phone primary-color mr-1" aria-hidden="true"></i>704.684.4751</a>
      </div>
    </div>
  </div>
</div>


