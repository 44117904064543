<section class="pr-3 pl-3">
  <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Dashboard</h2>
  <div class="row justify-content-center">
    <div class="col-lg-9 col-md-12">
      <div class="row">
        <div class="col-lg-6">
          <div class="row h-100">
            <div class="col-12 mb-3">
              <div class="border p-3 mb-3 card secondary-light-bg-color border-secondary rounded-8 h-100">
                <h3 class="m-0 font-weight-bold font-18 text-black-60">No of Sign Up</h3>
                <hr />
                <div class="row text-center">
                  <div class="col-lg-6">
                    <h4 class="font-18 primary-color font-weight-normal">Today</h4>
                    <i class="fa fa-desktop" aria-hidden="true"></i><span class="ml-2 font-18 font-weight-bold secondary-color">52</span>
                  </div>
                  <div class="col-lg-6">
                    <h4 class="font-18 primary-color font-weight-normal">Yesterday</h4>
                    <i class="fa fa-desktop" aria-hidden="true"></i><span class="ml-2 font-18 font-weight-bold secondary-color">104</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="border p-3 mb-3 card secondary-light-bg-color border-secondary rounded-8 h-100">
                <h3 class="m-0 font-weight-bold font-18 text-black-60">Payment Report</h3>
                <hr />
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb card-breadcrumb bg-transparent p-0">
                    <li class="breadcrumb-item" aria-current="page"><b>Show</b></li>
                    <li class="breadcrumb-item current-active"><a href="#">Today</a></li>
                    <li class="breadcrumb-item "><a href="#">Yesterday</a></li>
                    <li class="breadcrumb-item"><a href="#">Last Week</a></li>
                    <li class="breadcrumb-item"><a href="#">Last Month</a></li>
                    <li class="breadcrumb-item"><a href="#">Last Year</a></li>
                  </ol>
                </nav>
                <div class="row mt-3 text-center">
                  <div class="col-lg-6">
                    <h4 class="font-18 primary-color font-weight-normal">Return Count</h4>
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    <span class="ml-2 font-18 font-weight-bold secondary-color">120</span>
                  </div>
                  <div class="col-lg-6">
                    <h4 class="font-18 primary-color font-weight-normal">Total Payment</h4>
                    <i class="fa fa-money" aria-hidden="true"></i>
                    <span class="ml-2 font-18 font-weight-bold secondary-color">$360</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-6">
          <div class="border p-3 card secondary-light-bg-color border-secondary rounded-8 h-100">
            <h3 class="m-0 font-weight-bold font-18 text-black-60">Filing Report</h3>
            <hr />
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb card-breadcrumb bg-transparent p-0">
                <li class="breadcrumb-item active" aria-current="page"><b>Show</b></li>
                <li class="breadcrumb-item"><a href="#">Today</a></li>
                <li class="breadcrumb-item current-active"><a href="#">Yesterday</a></li>
                <li class="breadcrumb-item"><a href="#">Last Week</a></li>
                <li class="breadcrumb-item"><a href="#">Last Month</a></li>
                <li class="breadcrumb-item"><a href="#">Last Year</a></li>
              </ol>
            </nav>
            <table class="table table-bordered mt-3 mb-0">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Forms</th>
                  <th scope="col">E-file Count</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">Form W-2</td>
                  <td>45</td>
                </tr>
                <tr>
                  <td scope="row">Form 1099-MISC</td>
                  <td>30</td>
                </tr>
                <tr>
                  <td scope="row">Form 1099-NEC</td>
                  <td>28</td>
                </tr>
                <tr>
                  <td scope="row">Form 941</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td scope="row">Form 940</td>
                  <td>24</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="border rounded-8 p-3 mt-4">
    <h3 class="mt-0 font-weight-bold font-22 text-black-60">Customer Follow Up</h3>
    <hr />
    <div class="row align-items-center">
      <div class="col-lg-4">
        <div class="d-flex align-items-center">
          <span class="primary-color mr-3">Show</span>
          <mat-form-field class="w-400px mat-no-label">
            <mat-select [(value)]="selectedFollowup">
              <mat-option [value]="option.value" *ngFor="let option of followup">{{ option.viewValue }}</mat-option>
            </mat-select>
          </mat-form-field>
                <!--<div class="dropdown w-100">
                  <button class="btn btn-secondary dropdown-toggle font-14 shadow-none" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Today Follow Up
                  </button>
                  <div class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item p-2 text-decoration-none font-14" href="#">Today Follow Up</a>
                    <a class="dropdown-item p-2 text-decoration-none font-14" href="#">Past Due Follow Up</a>
                    <a class="dropdown-item p-2 text-decoration-none font-14" href="#">Future Follow Up</a>
                  </div>
                </div>-->
        </div>
      </div>
      <div class="col-lg-8">
        <h4 class="text-dark mb-0 text-right font-weight-bold font-18">Today's Date: Nov 17, 2020</h4>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-lg-12 text-right">
        <form class="form-inline float-right w-25">
          <input class="form-control d-block w-100 text-left rounded-0 p-0 border-top-0 border-left-0 border-right-0 border-bottom shadow-none" type="search" placeholder="Search" aria-label="Search">
        </form>
      </div>
    </div>

    <!-- box 1 -->
    <div class="border p-3 rounded-8 card mt-4">
      <div class="row">
        <div class="col-12">
          <a href="" class="font-weight-500 primary-color font-20 text-decoration">johnsmith@gmail.com</a>
          <label class="text-black-50 mr-1 mb-0">Contacted  on</label>
          <span class="text-italic">07/26/2020 06:23 PM (EST)</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-4">
          <label class="text-black-50 mr-1 mb-0">Follow Up Date:</label>
          <span class="text-italic">07/26/2020 10:00 AM (EST)</span>
        </div>
        <div class="col-lg-4">
          <div class="d-inline-block w-100">
            <label class="text-black-50 mr-1 mb-0">Status:</label>
            <mat-form-field class="w-50 mat-no-label">
              <mat-select [(value)]="selectedStatus">
                <mat-option [value]="option.value" *ngFor="let option of status">{{ option.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="d-inline-block w-100">
            <label class="text-black-50 mr-1 mb-0">Assigned To:</label>
            <mat-form-field class="w-50 mat-no-label">
              <mat-select [(value)]="selectedAssign">
                <mat-option [value]="option.value" *ngFor="let option of assign">{{ option.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <hr />
        <div class="">
          <div class=""><label class="w-8 text-black-50 d-inline-block text-right">Next Activity:</label> No further follow up required</div>
          <div class=""><label class="w-8 text-black-50 d-inline-block text-right">What:</label> User is unable to login into his account</div>
          <div class=""><label class="w-8 text-black-50 d-inline-block text-right">Comments:</label> Advised the client to reset the password</div>
        </div>
    </div>
    <!-- box 1 -->
    <!-- box 1 -->
    <div class="border p-3 rounded-8 card mt-4">
      <div class="row">
        <div class="col-12">
          <a href="" class="font-weight-500 primary-color font-20 text-decoration">alexanderdaniel@gmail.com</a>
          <label class="text-black-50 mr-1 mb-0">Contacted  on</label>
          <span class="text-italic">07/26/2020 10:00 AM (EST)</span>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-4">
          <label class="text-black-50 mr-1 mb-0">Follow Up Date:</label>
          <span class="text-italic">07/26/2020 10:00 AM (EST)</span>
        </div>
        <div class="col-lg-4">
          <div class="d-inline-block w-100">
            <label class="text-black-50 mr-1 mb-0">Status:</label>
            <mat-form-field class="w-50 mat-no-label">
              <mat-select [(value)]="selectedStatus">
                <mat-option [value]="option.value" *ngFor="let option of status">{{ option.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="d-inline-block w-100">
            <label class="text-black-50 mr-1 mb-0">Assigned To:</label>
            <mat-form-field class="w-50 mat-no-label">
              <mat-select [(value)]="selectedAssign">
                <mat-option [value]="option.value" *ngFor="let option of assign">{{ option.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <hr />
        <div class="">
          <div class=""><label class="w-8 text-black-50 d-inline-block text-right">Next Activity:</label> No further follow up required</div>
          <div class=""><label class="w-8 text-black-50 d-inline-block text-right">What:</label> User is unable to login into his account</div>
          <div class=""><label class="w-8 text-black-50 d-inline-block text-right">Comments:</label> Advised the client to reset the password</div>
        </div>
        <!--<div class="col-lg-1 text-right">
          <a href="" class="primary-color font-18" data-toggle="modal" data-target="#callHistory-popup">
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
          </a>
        </div>-->
    </div>
    <!-- box 1 -->

  </div>
</section>
