<section class="container-fluid">
  <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Reports</h2>
  <div class="wrapper-box p-3">
    <div class="row">
      <div class="col-lg-3">
        <a href="signup-report" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon11.png" alt="Sign Up">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">Sign Up Report</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="payment-reports" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon1.png" alt="Payment Report">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">Payment Report</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="#" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon2.png" alt="Refund/Void Report">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">Refund/Void Report</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="page3d.html" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon3.png" alt="Filing Report">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">Filing Report</h5>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg-3">
        <a href="#" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon4.png" alt="User Status Report">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">User Status Report</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="#" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon5.png" alt="User Input Report">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">User Input Report</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="#" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon6.png" alt="Postal Delivery Status">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">Postal Delivery Status</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="#" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon7.png" alt="Bulk Upload Status Report">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">Bulk Upload Status Report</h5>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg-3">
        <a href="#" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon8.png" alt="Credit Report">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">Credit Report</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="#" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon9.png" alt="Email Notification Report">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">Email Notification Report</h5>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-3">
        <a href="#" class="report-anchor d-block">
          <div class="media border report-grid-block rounded-8 d-flex align-items-center">
            <img class="mr-3 img-fluid" src="images/icon10.png" alt="Activity Log Report">
            <div class="media-body">
              <h5 class="my-0 secondary-color font-weight-semibold font-18">Activity Log Report</h5>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
