import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLoginComponent } from './views/admin-login/admin-login.component';
import { LayoutLoginComponent } from './views/shared/layout-login/layout-login.component';
import { DashboardDetailsComponent } from './views/dashborad/dashboard-details/dashboard-details.component';
import { PricingDetailsComponent } from './views/pricing/pricing-details/pricing-details.component';
import { ReportDetailsComponent } from './views/reports/report-details/report-details.component';
import { AdminDetailsComponent } from './views/admin/admin-details/admin-details.component';
import { UserDetailsComponent } from './views/dashborad/user-details/user-details.component';
import { SignUpReportsComponent } from './views/reports/sign-up-reports/sign-up-reports.component';
import { PaymentReportsComponent } from './views/reports/payment-reports/payment-reports.component';
import { AddCrediteComponent } from './views/dashborad/add-credite/add-credite.component';
import { PaymentMethodComponent } from './views/dashborad/payment-method/payment-method.component';
import { PaymentMethodDetailsComponent } from './views/dashborad/payment-method-details/payment-method-details.component';
import { CheckDetailsComponent } from './views/dashborad/check-details/check-details.component';
import { InvoiceformComponent } from './views/invoiceform/invoiceform.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'login-page', component: AdminLoginComponent },
  { path: 'dashboard', component: DashboardDetailsComponent },
  { path: 'pricing', component: PricingDetailsComponent },
  { path: 'report', component: ReportDetailsComponent },
  { path: 'admin', component: AdminDetailsComponent },
  { path: 'user-details', component: UserDetailsComponent },
  { path: 'signup-report', component: SignUpReportsComponent},
  { path: 'payment-reports', component: PaymentReportsComponent },
  { path: 'add-credite', component: AddCrediteComponent },
  { path: 'payment-method', component: PaymentMethodComponent },
  { path: 'payment-method-details', component: PaymentMethodDetailsComponent },
  { path: 'check-details', component: CheckDetailsComponent },
  { path: 'invoiceform', component: InvoiceformComponent },
  //{ path: 'login', component: LayoutLoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
