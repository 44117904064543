<section class="container-fluid">
  <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Prepaid Credits Payment</h2>
  <div class="row">
    <div class="col-lg-10">
      <h6 class="mb-2 font-16 primary-color font-weight-bold">
        Order Summary
      </h6>
      <div class="order-summary mt-2 p-2  mb-3">
        <table class="w-100">
          <tr>
            <td>
              <span class="label-gray mr-1">Name:</span>
              <span class="font-weight-semi-bold">Mathew John Junior</span>
            </td>
            <td>
              <span class="label-gray mr-1">Email:</span>
              <span class="font-weight-semi-bold">mathewjohn@gmail.com</span>
            </td>
            <td>
              <span class="label-gray mr-1">Order Id:</span>
              <span class="font-weight-semi-bold">DE4332</span>
            </td>
            <td>
              <span class="label-gray mr-1">Order Date:</span>
              <span class="font-weight-semi-bold">Dec 20, 2020</span>
            </td>
          </tr>
        </table>
        <div><hr /></div>
        <table class="w-100 order-summary-table">
          <thead>
            <tr>
              <th class="pb-3 font-16 primary-color">Description</th>
              <th class="pb-3 text-center font-16 primary-color">Credits</th>
              <th class="text-right pb-3 font-16 primary-color">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pb-3 font-weight-semibold" colspan="3">
                Information Returns
              </td>
            </tr>
            <tr>
              <td class="pl-3 pb-2">
                Federal Filling
              </td>
              <td class="text-center pb-2 font-16 font-weight-semibold">
                25
              </td>
              <td class="text-right pb-2 font-16 font-weight-semibold">
                $250.60
              </td>
            </tr>
            <tr>
              <td class="pl-3">
                State Filling
              </td>
              <td class="text-center font-16 font-weight-semibold">
                25
              </td>
              <td class="text-right font-16 font-weight-semibold">
                $75.00
              </td>
            </tr>
            <tr><td colspan="4"> <hr /></td></tr>
            <tr>
              <td class="pb-3  font-weight-semibold" colspan="3">
                94x Series
              </td>
            </tr>
            <tr>
              <td class="pl-3">
                Federal Filling
              </td>
              <td class="text-center font-16 font-weight-semibold">
                05
              </td>
              <td class="text-right font-16 font-weight-semibold">
                $75.00
              </td>
            </tr>
            <tr><td colspan="4"> <hr /></td></tr>
            <tr>
              <td class="pb-3  font-weight-semibold" colspan="3">
                Add-Ons
              </td>
            </tr>
            <tr>
              <td class="pl-3">
                Postal Mailling
              </td>
              <td class="text-center  font-16 font-weight-semibold">
                05
              </td>
              <td class="text-right font-16 font-weight-semibold">
                $7.60
              </td>
            </tr>
            <tr><td colspan="4"><hr /></td></tr>
            <tr>
              <td class="text-left" colspan="2"><b>Total Amount</b></td>
              <td class="text-right primary-color font-18"><b>$154.5</b></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="add-count-btn text-center">
        <a href="#" data-toggle="modal" data-target="#editgroup-popup" class="btn btn-primary btn-lg btn-primary-two mt-0">Pay Now</a>
      </div>

      <div class="modal fade popup-modal-style" id="editgroup-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              Credit Card Details
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form class="">
                <div>
                  <h4>
                    Make payment to remove watermark and download or email pay stubs.
                    <div class="text-center float-right">
                      <div class="card rounded-8 border p-2 d-inline-block">
                        <span>Your order amount is</span>
                        <span class="font-20 ml-2 primary-color">$2.98</span>
                      </div>
                    </div>
                  </h4>
                </div>
               
                <div class="orderr_details">
                  <h2 class="float-left m-0">Card Details</h2>
                  <div class="clearfix"></div>
                  <div class="row">
                    <div class="col-xl-4 col-md-6">
                      <mat-form-field class="example-form-field w-100">
                        <mat-label>Name on the Card<span class="red">*</span></mat-label>
                        <input matInput type="text">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-md-6">
                      <mat-form-field class="example-form-field w-100">
                        <mat-label>Card Number<span class="red">*</span></mat-label>
                        <input matInput type="text">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-md-12">
                      <div class="row">
                        <div class="col-xl-7 col-sm-6 col-12 res-mb-10">
                          <mat-form-field class="example-form-field w-100">
                            <mat-label>Month / Year<span class="red">*</span></mat-label>
                            <input matInput type="text">
                            <mat-hint class="example-text">e.g.:  02/25</mat-hint>
                          </mat-form-field>
                        </div>
                        <div class="col-xl-5 col-sm-6 col-12">
                          <mat-form-field  class="example-form-field w-100">
                            <mat-label>CVV<span class="red">*</span></mat-label>
                            <input matInput type="text">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="spacer20"></div>
                <div class="orderr_details">
                  <h2 class="m-0">
                    Billing Address
                  </h2>
                  <div class="row">
                    <div class="col-lg-4 col-md-6">
                      <mat-form-field class="example-form-field w-100">
                        <mat-label>Country<span class="red">*</span></mat-label>
                        <mat-select formControlName="Country" tabindex="6">
                          <mat-option>--Select--</mat-option>
                          <mat-option></mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <mat-form-field class="example-form-field w-100">
                        <mat-label>Address Line 1<span class="red">*</span></mat-label>
                        <input matInput type="text">
                        <mat-hint class="example-text">e.g.: 123 Main Street</mat-hint>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <mat-form-field class="example-form-field w-100">
                        <mat-label>Address Line 2</mat-label>
                        <input matInput type="text">
                        <mat-hint class="example-text">e.g.: APT 905</mat-hint>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <mat-form-field class="example-form-field w-100">
                        <mat-label>City<span class="red">*</span></mat-label>
                        <input matInput type="text">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <mat-form-field class="example-form-field w-100">
                        <mat-label>State<span class="red">*</span></mat-label>
                        <mat-select formControlName="StateCode" tabindex="10">
                          <mat-option>--Select--</mat-option>
                          <mat-option></mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-4 col-md-6">
                      <mat-form-field class="example-form-field w-100">
                        <mat-label>ZIP Code<span class="red">*</span></mat-label>
                        <input matInput type="text">
                      </mat-form-field>
                    </div>
                  </div>
                  <div>
                    <span class="font-italic"><b>Note:</b> Billing address should match the address on the credit card statement.</span>
                  </div>
                </div>
                <div class="dialog-footer text-center m-auto pt-0">
                  <div class="d-flex align-items-top credit-card-btn">
                    <div class="mt-2 text-left">
                      <div class="alert-yellow p-2 res-mr-0">
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer text-center d-inline-block m-0">
              <button type="submit" class="btn btn-primary btn-sm m-0">
                <span class="mr-2"><i class="fa fa-credit-card mr-2" aria-hidden="true"></i>Pay $2.98</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


