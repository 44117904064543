import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-master',
  templateUrl: './layout-master.component.html',
  styleUrls: ['./layout-master.component.css']
})
export class LayoutMasterComponent implements OnInit {
currentyear: number = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void {
  }

}
