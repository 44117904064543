<section class="container-fluid">
  <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Prepaid Credits Payment</h2>
  <div class="row mb-3 justify-content-center">
    <div class="col-lg-5">
      <h6 class="mb-2 font-16 primary-color font-weight-bold">
        Order Summary
      </h6>
      <div class="order-summary mt-2 p-2">
        <div>
          <div class="d-inline-block">
            <span class="label-gray mr-1">Name:</span>
            <span class="font-weight-semi-bold">Mathew John Junior</span>
          </div>
          <div class="d-inline-block float-right">
            <span class="label-gray mr-1">Email:</span>
            <span class="font-weight-semi-bold">mathewjohn@gmail.com</span>
          </div>
        </div>
        <div><hr /></div>
        <table class="w-100 order-summary-table">
          <thead>
            <tr>
              <th class="pb-3">Description</th>
              <th class="text-right pb-3">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="mb-2">
                Information Returns
              </td>
              <td class="text-right pb-2 font-16 font-weight-semibold">
                $58.50
              </td>
            </tr>
            <tr>
              <td class="pb-2">
                94x Series
              </td>
              <td class="text-right pb-2 font-16 font-weight-semibold">
                $36.5
              </td>
            </tr>
            <tr>
              <td>
                Add-Ons
              </td>
              <td class="text-right font-16 font-weight-semibold">$60.00</td>
            </tr>
            <tr><td colspan="2"><hr /></td></tr>
            <tr>
              <td class="text-left"><b>Total Amount</b></td>
              <td class="text-right primary-color font-18"><b>$154.5</b></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="row main-tab-style m-0">
    <div class="col-md-2 mb-3 pl-0">
      <ul class="nav nav-pills flex-column outer-nav h-100" id="myTab" role="tablist">
        <li class="nav-item mb-3">
          <a class="nav-link active" id="send-payment-link" data-toggle="tab" href="#sendpaymentlink" role="tab" aria-controls="payment-link" aria-selected="true">Send Payment Link</a>
        </li>
        <li class="nav-item mb-3">
          <a class="nav-link" id="pay-check" data-toggle="tab" href="#check" role="tab" aria-controls="check-group" aria-selected="true">Check</a>
        </li>
        <li class="nav-item mb-3">
          <a class="nav-link" id="process-clients-card" data-toggle="tab" href="#processclientscard" role="tab" aria-controls="clients-card" aria-selected="false">Process Client's Card</a>
        </li>
      </ul>
    </div>
    <!-- /.col-md-4 -->

    <div class="col-md-10 px-0">
      <div class="tab-content main-outer-tab-content" id="myTabContent popup-page">
        <div class="tab-pane fade show active" id="sendpaymentlink" role="tabpanel" aria-labelledby="default-group-tab">
          <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Send Payment Link</h2>
          <p>Send a payment link to the user by email. The user can complete the payment by clicking the link in the email</p>
          <div class="left-15 right-15 bottom-15 position-absolute">
            <a href="/add-credite" class="btn btn-outline-primary btn-back mt-0 float-left"><i aria-hidden="true" class="fa fa-angle-left"></i> Back</a>
            <a href="#" data-toggle="modal" data-target="#editgroup-popup" class="btn btn-primary btn-sm float-right">Send Payment Link</a>
          </div>
        </div>

        <div class="tab-pane fade" id="check" role="tabpanel" aria-labelledby="default-group-tab">
          <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Check</h2>
          <p>Receive payment form the user through a check.</p>
          <div role="alert" class="alert alert-warning my-4"><b>Note: </b>  The payment for order is still pending. Once you receive the payment through check, you will have to come back and confirm the oder. </div>
          <div class="clearfix"></div>
          <div class="left-15 right-15 bottom-15 position-absolute">
            <a href="/report" class="btn btn-outline-primary btn-back mt-0 float-left"><i aria-hidden="true" class="fa fa-angle-left"></i> Back</a>
            <a href="/check-details" class="btn btn-primary btn-sm float-right">Save & Continue</a>
          </div>
        </div>

        <div class="tab-pane fade" id="processclientscard" role="tabpanel" aria-labelledby="default-group-tab">
          <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">
            Process Client's Card
            <span class="float-right">
              <a href="" data-toggle="modal" data-target="#editgroup-popup1" class="btn btn-primary btn-sm">Add New Card</a>
            </span>
          </h2>
          <p>Complete payment by entering the client's card details.</p>
          <div class="card rounded-8 border p-3">
            <table class="w-100 text-center paystub-info">
              <tr>
                <td class="width-50 text-center">
                  <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="1" class="mr-3"></mat-radio-button>
                    </mat-radio-group>
                </td>
                <td class="border-right">
                  VISA
                  <div class="label-gray font-14 mt-2">Card Type</div>
                </td>
                <td class="border-right">
                  Mathew JJ
                  <div class="label-gray font-14 mt-2">
                    Name on the card
                  </div>
                </td>
                <td class="border-right font-500">
                  XXXXXXXXX5454
                  <div class="label-gray font-14 mt-2">Card Number</div>
                </td>
                <td>
                  11/25
                  <div class="label-gray pr-0 font-14 mt-2"> Month/Year</div>
                </td>
              </tr>
            </table>
          </div>
          <div class="clearfix"></div>
          <div class="mt-4 left-15 right-15 bottom-15 position-absolute">
            <a href="/report" class="btn btn-outline-primary btn-back mt-0 float-left"><i aria-hidden="true" class="fa fa-angle-left"></i> Back</a>
            <span class="float-right d-flex align-items-center">
              <span class="align-middle">
                Don't send email receipt to the client
              </span>
              <a href="add-credite" class="btn btn-primary btn-sm ml-3">Process</a>
            </span>
          </div>
        </div>

        <!--Send Payment Link-->
        <div class="modal fade popup-modal-style" id="editgroup-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                Send Payment Link
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to send the payment link to the user's email address?</p>
              </div>
              <div class="modal-footer text-center d-inline-block m-0">
                <a href="/payment-method" class="btn btn-outline-primary btn-back mt-1 float-left" data-dismiss="modal">Cancel</a>
                <a href="/payment-method-details" class="btn btn-primary btn-sm float-right m-0">yes</a>
              </div>
            </div>
          </div>
        </div>

        <!--Add New Card-->
        <div class="modal fade popup-modal-style" id="editgroup-popup1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                Process Client's Card
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row fieldwidth100 mb-3">
                  <div class="col-lg-6">
                    <mat-form-field class="w-100">
                      <mat-label>Name on the Card*</mat-label>
                      <input matInput type="text">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label>Month / Year*</mat-label>
                      <input matInput type="text">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field class="w-100">
                      <mat-label>Card Number*</mat-label>
                      <input matInput type="text">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label>CCV*</mat-label>
                      <input matInput type="text">
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="modal-footer text-center d-inline-block m-0">
                <a href="/payment-method" data-dismiss="modal" class="btn btn-outline-primary btn-back mt-1 float-left">Cancel</a>
                <a href="/payment-method-details" class="btn btn-primary btn-sm float-right m-0">Save & Confirm the Order</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.col-md-8 -->
  </div>
</section>
