

  <app-layout-master></app-layout-master>


<ng-template #routerLayout>
  <router-outlet></router-outlet>
</ng-template>

<div class="alert-div">
  
  <router-outlet></router-outlet>
</div>
