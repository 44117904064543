<section class="container-fluid">
  <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Prepaid Credits-Payment</h2>
  <div class="row mb-4">
    <div class="col-lg-6 pr-0">
      <div class="order-summary p-2">
        <h6 class="mb-3 font-16 primary-color font-weight-bold">
          Order Summary
        </h6>
        <div class="mt-2">
          <div class="d-inline-block">
            <span class="mr-1">Name:</span>
            <span class="font-weight-semi-bold">Mathew John Junior</span>
          </div>
          <div class="d-inline-block float-right">
            <span class="label-gray mr-1">Email:</span>
            <span class="font-weight-semi-bold">mathewjohn@gmail.com</span>
          </div>
        </div>
        <div><hr/></div>
        <table class="w-100 order-summary-table">
          <thead>
            <tr>
              <th class="pb-3">Description</th>
              <th class="text-right pb-3">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="mb-2">
                Information Returns
              </td>
              <td class="text-right pb-2 font-16 font-weight-semibold">
                $58.50
              </td>
            </tr>
            <tr>
              <td class="pb-2">
                94x Series
              </td>
              <td class="text-right pb-2 font-16 font-weight-semibold">
                $36.5
              </td>
            </tr>
            <tr>
              <td>
                Add-Ons
              </td>
              <td class="text-right font-16 font-weight-semibold">$60.00</td>
            </tr>
            <tr><td colspan="2"><hr /></td></tr>
            <tr>
              <td class="text-left"><b>Total Amount</b></td>
              <td class="text-right primary-color font-18"><b>$154.5</b></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="tab-content border rounded-8 p-3 h-100" id="myTabContent popup-page">
        <div class="tab-pane fade show active" id="sendpaymentlink" role="tabpanel" aria-labelledby="default-group-tab">
          <h2 class="mt-0 mb-3 font-22 primary-color font-weight-bold">Check Details</h2>
          <p>To complete the order, you will need to update the check details after you receive the payment of <b class="primary-color">$325.60</b> from the user through check.</p>
          <p>Received payment through check? Click the button below and update check details.</p>
          <div class="text-center my-3">
            <a href="#" data-toggle="modal" data-target="#editgroup-popup1" class="btn btn-primary btn-sm">Update Check Details</a>
          </div>
        </div>

        <!--Add New Card-->
        <div class="modal fade popup-modal-style" id="editgroup-popup1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                Process Client's Card
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row fieldwidth100 justify-content-center align-items-center">
                  <div class="col-lg-6">
                    <mat-form-field class="w-100">
                      <mat-label>Bank Name</mat-label>
                      <input matInput type="text">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label>Check Number</mat-label>
                      <input matInput type="text">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label>Amount</mat-label>
                      <input matInput type="text">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <mat-label>Check Date</mat-label>
                      <input matInput type="text">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <div class="border-dashed rounded-8 text-center p-2">
                      <a href="#" class="text-decoration-none">
                        <img src="/Images/upload-step.png" class="py-3">
                        <p>
                          Upload document
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer text-center d-inline-block m-0">
                <a href="/payment-method" class="btn btn-primary btn-sm m-0">Pay</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</section>
