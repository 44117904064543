<div>
  <header class="shadow bg-white py-3 fixed-top">
    <nav class="navbar navbar-expand-lg navbar-light py-0">
      <div class="d-flex">
        <a class="navbar-brand" href="#"><img src="images/logo.png" class="img-fluid min-width-150" alt="Logo" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <form class="form-inline my-2 my-lg-0 pr-3">
          <mat-form-field class="w-400px mat-no-label">
            <input type="text" placeholder="Search by Business Name, Contact Name, or Email"
                  matInput [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option routerLink="/user-details">
                <div class="font-weight-bold">Span Tech LLC</div>
                <div class="align-middle">
                  <i class="fa fa-envelope primary-color mr-2" aria-hidden="true"></i>spantechnology@gmail.com
                </div>
              </mat-option>
              <mat-option routerLink="/user-details">
                <div class="font-weight-bold">Alexander Daniel</div>
                <div class="align-middle">
                  <i class="fa fa-envelope primary-color mr-2" aria-hidden="true"></i>alexanderdaniel@gmail.com
                </div>
              </mat-option>
              <mat-option routerLink="/user-details">
                <div class="font-weight-bold">Google LLC</div>
                <div class="align-middle">
                  <i class="fa fa-envelope primary-color mr-2" aria-hidden="true"></i>johnsmith@gmail.com
                </div>
              </mat-option>
              <mat-option routerLink="/user-details">
                <div class="font-weight-bold">Radington Corporation</div>
                <div class="align-middle">
                  <i class="fa fa-envelope primary-color mr-2" aria-hidden="true"></i>radingtoncorporation@gmail.com
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>

      </div>
      <div class="float-right text-right ml-auto">
        <div class="header-top text-right mb-2">
          <ul class="mb-0">
            <li class="d-inline-block">Welcome John <span class="secondary-color">[Administrator]</span></li>
            <li class="ml-3 d-inline-block"><a href="javascript:void(0);" class="btn btn-outline-danger px-3 py-1 shadow-none rounded-30">Log Out</a></li>
          </ul>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto secondary-color">
            <li class="nav-item active">
              <a class="nav-link secondary-color text-decoration-none" routerLink="/dashboard">Dashboard</a>
            </li>
            <li class="nav-item">
              <a class="nav-link secondary-color text-decoration-none" routerLink="/pricing">Pricing</a>
            </li>
            <li class="nav-item">
              <a class="nav-link secondary-color text-decoration-none" routerLink="/report">Reports</a>
            </li>
            <li class="nav-item">
              <a class="nav-link secondary-color text-decoration-none" routerLink="/admin">Admin</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>

  <!-- Page Content -->
  <main class="pt-120">
    <article>
      <div id="page-content-wrapper">

        <div class="inner-page-height">
          <div class="py-3">
            <router-outlet></router-outlet>
          </div>

        </div>
      </div>
    </article>
  </main>


  <!-- /#page-content-wrapper -->
</div>
<div class="clear"></div>
<!-- /footer -->
<footer>
  <p class="text-center mb-0">Copyright © {{ currentyear }} expressefile.com. All Rights Reserved.</p>
</footer>
