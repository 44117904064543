import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from './common/angular-material.module';
import { LayoutMasterComponent } from './views/shared/layout-master/layout-master.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminLoginComponent } from './views/admin-login/admin-login.component';
import { DashboardDetailsComponent } from './views/dashborad/dashboard-details/dashboard-details.component';
import { LayoutLoginComponent } from './views/shared/layout-login/layout-login.component';
import { PricingDetailsComponent } from './views/pricing/pricing-details/pricing-details.component';
import { ReportDetailsComponent } from './views/reports/report-details/report-details.component';
import { AdminDetailsComponent } from './views/admin/admin-details/admin-details.component';
import { UserDetailsComponent } from './views/dashborad/user-details/user-details.component';
import { SignUpReportsComponent } from './views/reports/sign-up-reports/sign-up-reports.component';
import { PaymentReportsComponent } from './views/reports/payment-reports/payment-reports.component';
import { AddDiscountComponent } from './views/pricing/add-discount/add-discount.component';
import { AddCrediteComponent } from './views/dashborad/add-credite/add-credite.component';
import { PaymentMethodComponent } from './views/dashborad/payment-method/payment-method.component';
import { PaymentMethodDetailsComponent } from './views/dashborad/payment-method-details/payment-method-details.component';
import { CheckDetailsComponent } from './views/dashborad/check-details/check-details.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InvoiceformComponent } from './views/invoiceform/invoiceform.component';
@NgModule({
  declarations: [
    AppComponent,
    LayoutMasterComponent,
    AdminLoginComponent,
    DashboardDetailsComponent,
    LayoutLoginComponent,
    PricingDetailsComponent,
    ReportDetailsComponent,
    AdminDetailsComponent,
    UserDetailsComponent,
    SignUpReportsComponent,
    PaymentReportsComponent,
    AddDiscountComponent,
    AddCrediteComponent,
    PaymentMethodComponent,
    PaymentMethodDetailsComponent,
    CheckDetailsComponent,
    InvoiceformComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMaterialModule,
    NgxChartsModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
