<section class="pr-3 pl-3">
    <div class="row">
    <div class="col-md-2 mb-3">
      <ul class="nav nav-pills flex-column outer-nav" role="tablist">
        <li class="nav-item">
          <a class="nav-link border border-primary text-decoration-none primary-color active text-decoration-none" id="default-group-pricing" data-toggle="tab" href="#report-tab1" role="tab" aria-controls="default-group" aria-selected="true">Account Information</a>
        </li>
        <li class="nav-item mt-3">
          <a class="nav-link border border-primary text-decoration-none primary-color" id="custom-group" data-toggle="tab" href="#report-tab2" role="tab" aria-controls="custom-group" aria-selected="true">Manage Access</a>
        </li>
        <li class="nav-item mt-3">
          <a class="nav-link border border-primary text-decoration-none primary-color" id="discount-coupons" data-toggle="tab" href="#report-tab3" role="tab" aria-controls="discount" aria-selected="false">Admin Configuration</a>
        </li>
        <li class="nav-item mt-3">
          <a class="nav-link border border-primary text-decoration-none primary-color" id="discount-coupons" data-toggle="tab" href="#report-tab4" role="tab" aria-controls="discount" aria-selected="false">Admin Customization</a>
        </li>

      </ul>
    </div>
    <div class="col-lg-10 pl-0">
      <div class="tab-content border border-primary px-3 pt-3 pb-2">
        <div class="tab-pane fade show active" id="report-tab1" role="tabpanel" aria-labelledby="default-group-tab">
          <div class="row mb-3 d-flex align-items-center">
            <div class="col-lg-6">
              <h2 class="mt-0 primary-color font-22 font-weight-bold">
                Account Information
              </h2>
            </div>
            <div class="col-lg-6">
              <a href="#" class="btn btn-primary btn-lg primary-bg-color shadow-none text-decoration-none border-0 font-16 py-2 px-5 text-white rounded-30 float-right">Buy More Credits</a>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label class="text-black-60 mr-1 mb-0 font-18">Credits Summary</label>
            </div>
            <div class="col-lg-6 text-right">
              <span class="text-italic">As on 20-Nov-2020</span>
            </div>
          </div>
          <div class="border p-3 rounded-8 card mt-3">
            <div class="row text-center">
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Account Name:</label>
                <span class="text-italic">Expressefile_102</span>
              </div>
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Primary Admin Name:</label>
                <span class="text-italic">Richard Smith</span>
              </div>
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Auto Recharge:</label>
                <span class="text-italic">Enabled</span>
              </div>
            </div>
            <div class="mt-3 text-center">
              <a href="#" class="mr-3"><i class="fa fa-globe primary-color mr-1" aria-hidden="true"></i> admin.expressefile.com</a>
              <a href="#" class="mr-3"> <i class="fa fa-envelope primary-color mr-1" aria-hidden="true"></i> richardsmith@expressefile.com</a>
              <a href="#"><i class="fa fa-phone primary-color mr-1" aria-hidden="true"></i> +1-(565)-56-5256</a>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">Information Returns Credits</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">6000</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">8000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-75 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">94x Series</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">3000</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">6000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-50 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">ACA Forms</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">1600</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">4000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">Mathew Shippings</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">500</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">2000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">DG Accounts</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">400</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">2000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="tab-pane fade" id="report-tab2" role="tabpanel" aria-labelledby="default-group-tab">
          <div class="row mb-3 d-flex align-items-center">
            <div class="col-lg-6">
              <h2 class="mt-0 primary-color font-22 font-weight-bold">
                Account Information
              </h2>
            </div>
            <div class="col-lg-6">
              <a href="#" class="btn btn-primary btn-lg primary-bg-color shadow-none text-decoration-none border-0 font-16 py-2 px-5 text-white rounded-30 float-right">Buy More Credits</a>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label class="text-black-60 mr-1 mb-0 font-18">Credits Summary</label>
            </div>
            <div class="col-lg-6 text-right">
              <span class="text-italic">As on 20-Nov-2020</span>
            </div>
          </div>
          <div class="border p-3 rounded-8 card mt-3">
            <div class="row text-center">
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Account Name:</label>
                <span class="text-italic">Expressefile_102</span>
              </div>
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Primary Admin Name:</label>
                <span class="text-italic">Richard Smith</span>
              </div>
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Auto Recharge:</label>
                <span class="text-italic">Enabled</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">Information Returns Credits</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">6000</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">8000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-75 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">94x Series</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">3000</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">6000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-50 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">ACA Forms</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">1600</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">4000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">Mathew Shippings</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">500</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">2000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">DG Accounts</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">400</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">2000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="tab-pane fade" id="report-tab3" role="tabpanel" aria-labelledby="default-group-tab">

          <div class="row mb-3 d-flex align-items-center">
            <div class="col-lg-6">
              <h2 class="mt-0 primary-color font-22 font-weight-bold">
                Account Information
              </h2>
            </div>
            <div class="col-lg-6">
              <a href="#" class="btn btn-primary btn-lg primary-bg-color shadow-none text-decoration-none border-0 font-16 py-2 px-5 text-white rounded-30 float-right">Buy More Credits</a>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label class="text-black-60 mr-1 mb-0 font-18">Credits Summary</label>
            </div>
            <div class="col-lg-6 text-right">
              <span class="text-italic">As on 20-Nov-2020</span>
            </div>
          </div>
          <div class="border p-3 rounded-8 card mt-3">
            <div class="row text-center">
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Account Name:</label>
                <span class="text-italic">Expressefile_102</span>
              </div>
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Primary Admin Name:</label>
                <span class="text-italic">Richard Smith</span>
              </div>
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Auto Recharge:</label>
                <span class="text-italic">Enabled</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">Information Returns Credits</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">6000</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">8000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-75 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">94x Series</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">3000</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">6000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-50 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">ACA Forms</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">1600</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">4000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">Mathew Shippings</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">500</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">2000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">DG Accounts</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">400</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">2000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="tab-pane fade" id="report-tab4" role="tabpanel" aria-labelledby="default-group-tab">
          <div class="row mb-3 d-flex align-items-center">
            <div class="col-lg-6">
              <h2 class="mt-0 primary-color font-22 font-weight-bold">
                Account Information
              </h2>
            </div>
            <div class="col-lg-6">
              <a href="#" class="btn btn-primary btn-lg primary-bg-color shadow-none text-decoration-none border-0 font-16 py-2 px-5 text-white rounded-30 float-right">Buy More Credits</a>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label class="text-black-60 mr-1 mb-0 font-18">Credits Summary</label>
            </div>
            <div class="col-lg-6 text-right">
              <span class="text-italic">As on 20-Nov-2020</span>
            </div>
          </div>
          <div class="border p-3 rounded-8 card mt-3">
            <div class="row text-center">
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Account Name:</label>
                <span class="text-italic">Expressefile_102</span>
              </div>
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Primary Admin Name:</label>
                <span class="text-italic">Richard Smith</span>
              </div>
              <div class="col-lg-4">
                <label class="label-gray mr-1 mb-0">Auto Recharge:</label>
                <span class="text-italic">Enabled</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">Information Returns Credits</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">6000</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">8000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-75 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">94x Series</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">3000</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">6000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-50 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">ACA Forms</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">1600</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">4000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">Mathew Shippings</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">500</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">2000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="border p-3 rounded-8 card mt-3">
                <h3 class="mt-0 font-weight-bold font-20 primary-color">DG Accounts</h3>
                <div class="row">
                  <div class="col-6">
                    <label class="label-gray mr-1 mb-0">Used</label>
                    <br />
                    <span class="text-italic">400</span>
                  </div>
                  <div class="col-6 text-right">
                    <label class="label-gray mr-1 mb-0">Available</label>
                    <br />
                    <span class="text-italic">2000</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="progress">
                      <div class="progress-bar w-25 primary-bg-color" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>

</section>

