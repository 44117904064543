export var returns = [
  {
    "name": "Balance",
    "value": 75
  },
  {
    "name": "Used",
    "value": 125
  }
];

export var returns1 = [
  {
    "name": "Balance",
    "value": 30
  },
  {
    "name": "Used",
    "value": 70
  }
];

export var returns2 = [
  {
    "name": "Balance",
    "value": 45
  },
  {
    "name": "Used",
    "value": 55
  } 
];




export var addons = [
  {
    "name": "Balance",
    "value": 50
  },
  {
    "name": "Used",
    "value": 80
  }
];

export var addons1 = [
  {
    "name": "Balance",
    "value": 20
  },
  {
    "name": "Used",
    "value": 30
  }
];

export var addons2 = [
  {
    "name": "Balance",
    "value": 20
  },
  {
    "name": "Used",
    "value": 30
  }
];

export var addons3 = [
  {
    "name": "Balance",
    "value": 10
  },
  {
    "name": "Used",
    "value": 20
  }
];

export var series = [
  {
    "name": "Balance",
    "value": 30
  },
  {
    "name": "Used",
    "value": 70
  }
];

export var series1 = [
  {
    "name": "Balance",
    "value": 30
  },
  {
    "name": "Used",
    "value": 70
  }
];
