import { Component, OnInit } from '@angular/core';
interface Followup {
  value: string;
  viewValue: string;
}
interface Status {
  value: string;
  viewValue: string;
}
interface Assign {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.css']
})
export class DashboardDetailsComponent implements OnInit {
  followup: Followup[] = [
    { value: 'Today', viewValue: 'Today Follow Up' },
    { value: 'Past', viewValue: 'Past Due Follow Up' },
    { value: 'Feature', viewValue: 'Feature Follow Up' }
  ];
  selectedFollowup = this.followup[0].value;

  status: Status[] = [
    { value: 'status1', viewValue: 'Awaiting Customer' },
    { value: 'status2', viewValue: 'Awaiting Support' },
    { value: 'status3', viewValue: 'Awaiting Tech' },
    { value: 'status4', viewValue: 'Ceased Follow Up' },
    { value: 'status5', viewValue: 'Completed' }
  ];
  selectedStatus = this.status[0].value;

  assign: Assign[] = [
    { value: 'assign1', viewValue: 'John Harper' },
    { value: 'assign2', viewValue: 'Veronica' },
    { value: 'assign3', viewValue: 'Sicilia Jone' },
    
  ];
  selectedAssign = this.assign[0].value;


  constructor() { }

  ngOnInit(): void {
  }

}
