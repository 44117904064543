import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-details',
  templateUrl: './pricing-details.component.html',
  styleUrls: ['./pricing-details.component.css']
})
export class PricingDetailsComponent implements OnInit {
  displayDiscount = false;
  addDiscount() {
  this.displayDiscount = true;
  }
  constructor() { }

  ngOnInit(): void {
 
  }

}
