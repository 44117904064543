<section class="container-fluid">
 
    <div class="row d-flex align-items-center mb-3">
      <div class="col-lg-6">
        <nav aria-label="breadcrumb" class="mb-2">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/report">Reports</a></li>
            <li class="breadcrumb-item active" aria-current="page">Sign Up Report</li>
          </ol>
        </nav>
      </div>
      <div class="col-lg-6">
        <div class="text-right">
          <a href="/report" class="btn btn-outline-primary btn-back mt-0"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</a>
        </div>
      </div>
    </div>
    <div class="row main-tab-style">
      <div class="col-md-2 mb-3">
        <ul class="nav nav-pills flex-column outer-nav h-100" id="myTab" role="tablist">
          <li class="nav-item mb-3">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#signUpReport-maintab1" role="tab" aria-controls="home" aria-selected="true">Today Report</a>
          </li>
          <li class="nav-item mb-3">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#signUpReport-maintab2" role="tab" aria-controls="profile" aria-selected="false">Sign Up Count</a>
          </li>
          <li class="nav-item mb-3">
            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#signUpReport-maintab3" role="tab" aria-controls="contact" aria-selected="false">Monthly Report</a>
          </li>
          <li class="nav-item mb-3">
            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#signUpReport-maintab4" role="tab" aria-controls="contact" aria-selected="false">Yearly Report</a>
          </li>
        </ul>
      </div>
      <!-- /.col-md-4 -->
      <div class="col-md-10 pl-0">
        <div class="tab-content main-outer-tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="signUpReport-maintab1" role="tabpanel" aria-labelledby="home-tab">
            <div class="row justify-content-center">
              <div class="col-lg-3">
                <div class="card secondary-light-bg-color border-secondary shadow-sm text-center p-2">
                  <p class="font-16 font-weight-semibold mb-0"><span class="font-22 font-weight-bold secondary-color">25</span><br />Total No of Sign Up</p>
                </div>
              </div>
            </div>
            <table class="table table-bordered mt-4 table-head-bg">
              <thead class="thead-light">
                <tr>
                  <th scope="col" class="width-50 text-center">S.No.</th>
                  <th scope="col">Contact Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Device Info</th>
                  <th scope="col">Sign Up Date(EST)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>
                  <td>Anthony</td>
                  <td>ant************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-desktop font-weight-semi-bold" aria-hidden="true"></i> Mobile, <span class="font-weight-semi-bold">Chrome 86.0.4240.185</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">157.51.1.169</span></p>
                  </td>
                  <td>11/13/2020 02:07 AM</td>
                </tr>

                <tr>
                  <td class="text-center">2</td>
                  <td>Jackson</td>
                  <td>jac************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-apple" aria-hidden="true"></i> User's, <span class="font-weight-semi-bold">iPhone</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">117.197.201.241</span></p>
                  </td>
                  <td>11/12/2020 12:23 AM</td>
                </tr>

                <tr>
                  <td class="text-center">3</td>
                  <td>George</td>
                  <td>geo************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-apple" aria-hidden="true"></i> User's, <span class="font-weight-semi-bold">iPhone</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">157.51.1.169</span></p>
                  </td>
                  <td>11/13/2020 02:07 AM</td>
                </tr>
                <tr>
                  <td class="text-center">4</td>
                  <td>Christopher</td>
                  <td>chr************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-desktop font-weight-semi-bold" aria-hidden="true"></i> windows-10, <span class="font-weight-semi-bold">Chrome 86.0.4240.185</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">57.51.1.109</span></p>
                  </td>
                  <td>11/11/2020 03:17 AM</td>
                </tr>

                <tr>
                  <td class="text-center">5</td>
                  <td>Isabella</td>
                  <td>isa************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-android" aria-hidden="true"></i> ANDROID 9 vivo 1907, <span class="font-weight-semi-bold">iPhone</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">17.10.8.209</span></p>
                  </td>
                  <td>10/13/2020 02:07 AM</td>
                </tr>


              </tbody>
            </table>
            <div class="tab-footer-icons float-right">
              <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
              <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
              <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
              <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
            </div>
          </div>



          <div class="tab-pane fade" id="signUpReport-maintab2" role="tabpanel" aria-labelledby="profile-tab">
            <div class="row align-items-end">
              <div class="col-lg-3">
                <label class="input-label">Filter Type</label>
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Last Month
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">Today</a>
                    <a class="dropdown-item" href="#">Yesterday</a>
                    <a class="dropdown-item" href="#">This Week</a>
                    <a class="dropdown-item" href="#">This Month</a>
                    <a class="dropdown-item" href="#">Last Month</a>
                    <a class="dropdown-item" href="#">Custom</a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <form action="/action_page.php" class="date-picker-field">
                  <label class="input-label mb-0"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> Start Date</label>
                  <input type="date" id="birthday" name="birthday">
                </form>
              </div>
              <div class="col-lg-3">
                <form action="/action_page.php" class="date-picker-field">
                  <label class="input-label mb-0"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> End Date</label>
                  <input type="date" id="birthday" name="birthday">
                </form>
              </div>
              <div class="col-lg-3 text-right">
                <div class="card secondary-light-bg-color border-secondary shadow-sm text-center p-2">
                  <p class="font-16 font-weight-semibold mb-0"><span class="font-22 font-weight-bold secondary-color">25</span><br />Total No of Sign Up</p>
                </div>
              </div>
            </div>
            <table class="table table-bordered mt-4 table-head-bg">
              <thead class="thead-light">
                <tr>
                  <th scope="col" class="width-50 text-center">S.No.</th>
                  <th scope="col">Contact Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Device Info</th>
                  <th scope="col">Sign Up Date(EST)</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">1</td>
                  <td>Isabella</td>
                  <td>isa************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-desktop font-weight-semi-bold" aria-hidden="true"></i> Mobile, <span class="font-weight-semi-bold">Chrome 86.0.4240.185</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">157.51.1.169</span></p>
                  </td>
                  <td>11/13/2020 02:07 AM</td>
                </tr>

                <tr>
                  <td class="text-center">2</td>
                  <td>Christopher</td>
                  <td>chr************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-apple" aria-hidden="true"></i> User's, <span class="font-weight-semi-bold">iPhone</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">117.197.201.241</span></p>
                  </td>
                  <td>11/12/2020 12:23 AM</td>
                </tr>

                <tr>
                  <td class="text-center">3</td>
                  <td>Alondra</td>
                  <td>alo************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-apple" aria-hidden="true"></i> User's, <span class="font-weight-semi-bold">iPhone</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">157.51.1.169</span></p>
                  </td>
                  <td>11/13/2020 02:07 AM</td>
                </tr>
                <tr>
                  <td class="text-center">4</td>
                  <td>Michael</td>
                  <td>mic************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-desktop font-weight-semi-bold" aria-hidden="true"></i> Mobile, <span class="font-weight-semi-bold">Chrome 86.0.4240.185</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">57.51.1.109</span></p>
                  </td>
                  <td>11/11/2020 03:17 AM</td>
                </tr>

                <tr>
                  <td class="text-center">5</td>
                  <td>Matthew</td>
                  <td>mat************@gm***.c**</td>
                  <td>
                    <p class="mBot5"><i class="fa fa-android" aria-hidden="true"></i> ANDROID 9 vivo 1907, <span class="font-weight-semi-bold">iPhone</span></p>
                    <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">17.10.8.209</span></p>
                  </td>
                  <td>10/13/2020 02:07 AM</td>
                </tr>


              </tbody>
            </table>
            <div class="tab-footer-icons float-right">
              <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
              <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
              <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
              <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
            </div>
          </div>

          <div class="tab-pane fade monthly" id="signUpReport-maintab3" role="tabpanel" aria-labelledby="profile-tab">
            <div class="monthly-details">
              <div class="row align-items-end">
                <div class="col-lg-3">
                  <form action="/action_page.php" class="date-picker-field">
                    <label class="input-label mb-0"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> Start Date</label>
                    <input type="date" id="birthday" name="birthday">
                  </form>
                </div>
                <div class="col-lg-3">
                  <form action="/action_page.php" class="date-picker-field">
                    <label class="input-label mb-0"><i class="fa fa-calendar-plus-o" aria-hidden="true"></i> End Date</label>
                    <input type="date" id="birthday" name="birthday">
                  </form>
                </div>
                <div class="col-lg-3">
                  <a href="#" class="btn btn-primary btn-lg btn-primary-two mt-0">Get Records</a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-8">
                  <table class="table table-bordered mt-4 table-head-bg w-100 month-report-table">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">Month</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><a href="#" class="link-text month">May</a></td>
                        <td>149</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">June</a></td>
                        <td>177</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">July</a></td>
                        <td>160</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">August</a></td>
                        <td>86</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">Septemper</a></td>
                        <td>48</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">October</a></td>
                        <td>35</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">November</a></td>
                        <td>27</td>
                      </tr>
                      <tr>
                        <td><b>Total</b></td>
                        <td><b>682</b></td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div class="mt-2">
                <img src="images/graph-02.png" class="img-fluid" alt="Graph" />
              </div>
            </div>
            <div class="month-detail" style="display:none;">
              <div class="row align-items-center justify-content-center">

                <div class="col-lg-12 mb-2">
                  <a class="float-left back-monthly mr-2" href="javascript:void(0);"><i class="fa fa-arrow-left font-20" aria-hidden="true"></i></a>
                  <h2 class="float-left mt-0 mb-3 font-22 primary-color font-weight-bold">May Month Sign Up</h2>

                  <div class="clearfix"></div>
                </div>
                <div class="col-lg-4">
                  <div class="card secondary-light-bg-color border-secondary shadow-sm text-center p-2">
                    <p class="font-16 font-weight-semibold mb-0"><span class="font-22 font-weight-bold secondary-color">149</span><br />Total No of Sign Up</p>
                  </div>
                </div>
              </div>
              <table class="table table-bordered mt-4 table-head-bg">
                <thead class="thead-light">
                  <tr>
                    <th scope="col" class="width-50 text-center">S.No.</th>
                    <th scope="col">Contact Name</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Device Info</th>
                    <th scope="col">Sign Up Date(EST)</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>
                    <td>Isabella</td>
                    <td>isa************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-desktop font-weight-semi-bold" aria-hidden="true"></i> Mobile, <span class="font-weight-semi-bold">Chrome 86.0.4240.185</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">157.51.1.169</span></p>
                    </td>
                    <td>11/13/2020 02:07 AM</td>
                  </tr>

                  <tr>
                    <td class="text-center">2</td>
                    <td>Christopher</td>
                    <td>chr************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-apple" aria-hidden="true"></i> User's, <span class="font-weight-semi-bold">iPhone</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">117.197.201.241</span></p>
                    </td>
                    <td>11/12/2020 12:23 AM</td>
                  </tr>

                  <tr>
                    <td class="text-center">3</td>
                    <td>Alondra</td>
                    <td>alo************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-apple" aria-hidden="true"></i> User's, <span class="font-weight-semi-bold">iPhone</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">157.51.1.169</span></p>
                    </td>
                    <td>11/13/2020 02:07 AM</td>
                  </tr>
                  <tr>
                    <td class="text-center">4</td>
                    <td>Michael</td>
                    <td>mic************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-desktop font-weight-semi-bold" aria-hidden="true"></i> Mobile, <span class="font-weight-semi-bold">Chrome 86.0.4240.185</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">57.51.1.109</span></p>
                    </td>
                    <td>11/11/2020 03:17 AM</td>
                  </tr>

                  <tr>
                    <td class="text-center">5</td>
                    <td>Matthew</td>
                    <td>mat************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-android" aria-hidden="true"></i> ANDROID 9 vivo 1907, <span class="font-weight-semi-bold">iPhone</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">17.10.8.209</span></p>
                    </td>
                    <td>10/13/2020 02:07 AM</td>
                  </tr>


                </tbody>
              </table>
              <div class="tab-footer-icons float-right">
                <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>

          <div class="tab-pane fade yearly-report" id="signUpReport-maintab4" role="tabpanel" aria-labelledby="profile-tab">
            <div class="monthly-details">
              <div class="row align-items-end">
                <div class="col-lg-2">
                  <label class="input-label">Filter Type</label>
                  <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      2020
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="#">2021</a>
                      <a class="dropdown-item" href="#">2022</a>
                      <a class="dropdown-item" href="#">2023</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <a href="#" class="btn btn-primary btn-lg btn-primary-two mt-0">Get Records</a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-md-8">
                  <table class="table table-bordered mt-4 table-head-bg w-100 month-report-table">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">Month</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><a href="#" class="link-text month">May</a></td>
                        <td>149</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">June</a></td>
                        <td>177</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">July</a></td>
                        <td>160</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">August</a></td>
                        <td>86</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">Septemper</a></td>
                        <td>48</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">October</a></td>
                        <td>35</td>
                      </tr>
                      <tr>
                        <td><a href="#" class="link-text month">November</a></td>
                        <td>27</td>
                      </tr>
                      <tr>
                        <td><b>Total</b></td>
                        <td><b>682</b></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="mTop20">
                <img src="images/graph-02.png" class="img-fluid" alt="Graph" />
              </div>
            </div>
            <div class="month-detail" style="display:none;">
              <div class="row align-items-center justify-content-center">

                <div class="col-lg-12 mb-2">
                  <a class="float-left back-monthly mr-2" href="javascript:void(0);"><i class="fa fa-arrow-left font-20" aria-hidden="true"></i></a>
                  <h2 class="float-left mt-0 mb-3 font-22 primary-color font-weight-bold">May Month Sign Up</h2>

                  <div class="clearfix"></div>
                </div>
                <div class="col-lg-4">
                  <div class="card secondary-light-bg-color border-secondary shadow-sm text-center p-2">
                    <p class="font-16 font-weight-semibold mb-0"><span class="font-22 font-weight-bold secondary-color">149</span><br />Total No of Sign Up</p>
                  </div>
                </div>
              </div>
              <table class="table table-bordered mt-4 table-head-bg">
                <thead class="thead-light">
                  <tr>
                    <th scope="col" class="width-50 text-center">S.No.</th>
                    <th scope="col">Contact Name</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Device Info</th>
                    <th scope="col">Sign Up Date(EST)</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">1</td>
                    <td>Isabella</td>
                    <td>isa************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-desktop font-weight-semi-bold" aria-hidden="true"></i> Mobile, <span class="font-weight-semi-bold">Chrome 86.0.4240.185</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">157.51.1.169</span></p>
                    </td>
                    <td>11/13/2020 02:07 AM</td>
                  </tr>

                  <tr>
                    <td class="text-center">2</td>
                    <td>Christopher</td>
                    <td>chr************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-apple" aria-hidden="true"></i> User's, <span class="font-weight-semi-bold">iPhone</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">117.197.201.241</span></p>
                    </td>
                    <td>11/12/2020 12:23 AM</td>
                  </tr>

                  <tr>
                    <td class="text-center">3</td>
                    <td>Alondra</td>
                    <td>alo************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-apple" aria-hidden="true"></i> User's, <span class="font-weight-semi-bold">iPhone</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">157.51.1.169</span></p>
                    </td>
                    <td>11/13/2020 02:07 AM</td>
                  </tr>
                  <tr>
                    <td class="text-center">4</td>
                    <td>Michael</td>
                    <td>mic************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-desktop font-weight-semi-bold" aria-hidden="true"></i> Mobile, <span class="font-weight-semi-bold">Chrome 86.0.4240.185</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">57.51.1.109</span></p>
                    </td>
                    <td>11/11/2020 03:17 AM</td>
                  </tr>

                  <tr>
                    <td class="text-center">5</td>
                    <td>Matthew</td>
                    <td>mat************@gm***.c**</td>
                    <td>
                      <p class="mBot5"><i class="fa fa-android" aria-hidden="true"></i> ANDROID 9 vivo 1907, <span class="font-weight-semi-bold">iPhone</span></p>
                      <p class="mBot5">Ip Address: <span class="font-weight-semi-bold">17.10.8.209</span></p>
                    </td>
                    <td>10/13/2020 02:07 AM</td>
                  </tr>


                </tbody>
              </table>
              <div class="tab-footer-icons float-right">
                <a href="" class="mr-2"><i class="fa fa-step-backward" aria-hidden="true"></i></a>
                <a href="" class="move-icon mr-1"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
                <a href="" class="move-icon mr-2"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                <a href="" class="step-move"><i class="fa fa-step-forward" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.col-md-8 -->

</section>
