import { Component, OnInit } from '@angular/core';
import { returns, returns1, returns2 } from '../user-details/data';
import { addons, addons1, addons2, addons3 } from '../user-details/data';
import { series, series1 } from '../user-details/data';


interface Status {
  value: string;
  viewValue: string;
}
interface Assign {
  value: string;
  viewValue: string;
}

interface Catagory {
  value: string;
  viewValue: string;
}

interface Assignto {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  status: Status[] = [
    { value: 'status1', viewValue: 'Awaiting Customer' },
    { value: 'status2', viewValue: 'Awaiting Support' },
    { value: 'status3', viewValue: 'Awaiting Tech' },
    { value: 'status4', viewValue: 'Ceased Follow Up' },
    { value: 'status5', viewValue: 'Completed' }
  ];
  selectedStatus = this.status[0].value;

  assign: Assign[] = [
    { value: 'assign1', viewValue: 'John Harper' },
    { value: 'assign2', viewValue: 'Veronica' },
    { value: 'assign3', viewValue: 'Sicilia Jone' },

  ];
  selectedAssign = this.assign[0].value;
  

  catagory: Catagory[] = [
  { value: 'catagory1', viewValue: 'Sign Up' },
  { value: 'catagory2', viewValue: 'Form 94x Series' },
  { value: 'catagory3', viewValue: 'Information Returns W2/1099' },
  { value: 'catagory4', viewValue: 'Dashboard' },
  { value: 'catagory5', viewValue: 'Address Book' },
  { value: 'catagory6', viewValue: 'Form Filing' },
  { value: 'catagory7', viewValue: 'Payment' },
  { value: 'catagory8', viewValue: 'Others' },

  ];

selectedCatagory = this.catagory[0].value;


  ngOnInit(): void {
  }

  returns: any[];
  returns1: any[];
  returns2: any[];

  addons: any[];
  addons1: any[];
  addons2: any[];
  addons3: any[];

  series: any[];
  series1: any[];


  view: any[] = [300, 300];

  // options
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  legendPosition: string = 'below';
  explodeSlices: boolean = true;
  colorScheme = {
    domain: ['#49bfe0', '#1b426d']
  };
colorScheme1 = {
  domain: ['#ffc107', '#28a745']
  };

  constructor() {
    Object.assign(this, { returns, returns1, returns2 });
    Object.assign(this, { addons, addons1, addons2, addons3 });
    Object.assign(this, { series, series1 });
  }


  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
